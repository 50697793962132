const initialData = {
  tasks: {
    'task-1': { id: 'task-1', content: '' },
  },
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'Feature backlog',
      taskIds: ['task-1'],
    },
    "column-2": {
      id: "column-2",
      title: "Ready for development",
      taskIds: []
    },
    "column-3": {
      id: "column-3",
      title: "In development",
      taskIds: []
    },
    "column-4": {
      id: "column-4",
      title: "Ready for review",
      taskIds: []
    },
    "column-5": {
      id: "column-5",
      title: "Ready for deploy",
      taskIds: []
    },
    "column-6": {
      id: "column-6",
      title: "Completed",
      taskIds: []
    }
  },// Facilitate reordering of the columns
  columnOrder: ['column-1', 'column-2', 'column-3', 'column-4', 'column-5', 'column-6'],
};

export { initialData }