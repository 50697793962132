import styled, { css } from 'styled-components';
import { AddRowButton } from 'formik-antd';

const AddRowButtonStyled = styled(AddRowButton)`
    margin-top: 10px;
    margin-bottom: 5px;
`;

const ValidationMsg = styled.small`
    color: red;
    position: absolute;
    width: auto;
    left: 0px;
    top: 35px;
    background: aliceblue;
    padding: 0px 5px;
    border: 1px dotted red;
`;

export { AddRowButtonStyled, ValidationMsg };

// TODO: WORK ON COPY N PASTE FEATURE`
