import React, { FC, useState, useEffect } from 'react';

import { Upload, message, Modal, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { TheDivider } from '../../../Global.styled';
import { firebaseStorage, fbStorageTaskEventStateChanged } from '../../../firebase/firebaseConfig';
import ImgCrop from 'antd-img-crop';
import { isReallyNotEmpty } from './ramdaHelpers';
import { getBase64, DeleteIcon } from './ImageUploaderSettings';
import { last } from 'ramda';
import { CenterImgAndMaxWidth100 } from '../Channel.styled';

const { Dragger } = Upload;

interface Props {
    userId?: string;
    coverImg?: string;
    addCoverImg: (url: string) => void;
    type: 'channels' | 'contents' | 'users';
}

type FileProps = {
    name?: string;
    status?: string;
    uid: string;
    url?: string;
    preview?: any;
    originFileObj?: any;
};

const storageRef = firebaseStorage.ref();

const ImageUploader: FC<Props> = (props) => {
    const [uploadStart, setUploadStart] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(false);
    const [previewImage, setPreviewImage] = useState<string>(props.coverImg || '');
    const [previewVisible, setPreviewVisible] = useState(false);
    const [fileList, setFileList] = useState<any[] | []>([]);

    // todo: fix scrolling issue after uploading image
    // console.log('ImageUploader fileList', fileList);
    const aspectRatio = props.type === 'channels' ? 16 / 9 : 1;

    const cropProps = {
        aspect: aspectRatio,
        zoom: true,
        rotate: true,
        // modalWidth: '80%',
        modalTitle: 'Crop options',
    };

    // todo: add an info to notify only 10 images
    const ifGreaterThan10Files = fileList.length > 10;

    const defaultProps = {
        name: 'file',
        multiple: true,
        showUploadList: {
            showPreviewIcon: true,
            showRemoveIcon: true,
        },
        // listType: 'picture',
        disabled: ifGreaterThan10Files,
        fileList: fileList,
        async onPreview(file: FileProps) {
            // console.log('onPreview -> file', file);
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            setPreviewImage(file.url || file.preview);
            setPreviewVisible(true);
            if (file.url) {
                file.url.includes('firebasestorage') && props.addCoverImg(file.url);
            }
        },
        async onRemove(file: FileProps) {
            const imgPath = file.uid;
            const imgRef = storageRef.child(imgPath);

            imgRef
                .delete()
                .then(() => {
                    message.success(`${file.name} deleted.`);
                })
                .then(() => {
                    const filteredFileList = fileList.slice().filter((img: any) => img.uid !== imgPath);
                    setFileList(filteredFileList);
                    // set coverImg to first img
                    filteredFileList.length > 0 && props.addCoverImg(last(filteredFileList).url);
                })
                .catch((error) => {
                    console.log('onRemove -> error', error);
                    message.error(`${file.name} delete failed.`);
                });
        },
        beforeUpload(file: any) {
            const isImage = file.type.indexOf('image/') === 0;
            if (!isImage) {
                message.error('You can only upload an image file!');
            }

            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error('Image must smaller than 5MB!');
            }
            return isImage && isLt5M;
        },
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                // getBase64(info.file.originFileObj, (imageUrl: any) => setImageUrl(imageUrl));
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        customRequest(data: any) {
            const { file, onSuccess, onError } = data;
            const { userId, type } = props;
            // console.log('customRequest -> info', data);
            const imgName = `${type}/${userId}/cover-img-${new Date().getTime()}-${file.name}`;
            const imageRef = storageRef.child(imgName);
            const task = imageRef.put(file);

            task.on(
                fbStorageTaskEventStateChanged,
                (snapshot) => {
                    const progress = Math.round((100 * snapshot.bytesTransferred) / snapshot.totalBytes);
                    setUploadStart(true);
                    setUploadProgress(progress);
                    // message.success(`${file.name} file uploaded successfully.`);
                },
                (error) => {
                    console.log('onChange -> error', error);
                    // Handle error during the upload
                    onError(error);
                    setUploadError(true);
                    setUploadStart(false);
                },
                async () => {
                    await task.snapshot.ref
                        .getDownloadURL()
                        .then((downloadURL) => {
                            if (props.addCoverImg) {
                                props.addCoverImg(downloadURL);
                            }
                            onSuccess(null, downloadURL);
                        })
                        .catch((error) => {
                            onError(error);
                            setUploadError(true);
                        })
                        .finally(() => {
                            setUploadStart(false);
                            setUploadError(false);
                        });
                },
            );
        },
    };

    const [draggerProps, setDraggerProps] = useState<any>(defaultProps);

    // let newProps;
    useEffect(() => {
        if (props || fileList) {
            setDraggerProps({ props, ...defaultProps, fileList });
        }
    }, [props, fileList]);

    useEffect(() => {
        const { coverImg } = props;
        if (coverImg && isReallyNotEmpty(coverImg)) {
            setPreviewImage(coverImg);
        }
    }, [props.coverImg]);

    useEffect(() => {
        const { userId, type } = props;
        if (userId && isReallyNotEmpty(userId) && type && isReallyNotEmpty(type)) {
            // console.log('props', props);
            var listRef = storageRef.child(`${type}/${userId}`);
            // Find all the prefixes and items.
            listRef
                .listAll()
                .then((res) => {
                    res.prefixes.forEach((folderRef) => {
                        console.log('folderRef prefixes ->', folderRef);
                        // All the prefixes under listRef.
                        // You may call listAll() recursively on them.
                    });

                    const getAllDownloadURLs = (itemRef: any) => {
                        const { name, fullPath } = itemRef;

                        const item = itemRef
                            .getDownloadURL()
                            .then((url: string) => {
                                const imgDetails = { uid: fullPath, name: name, status: 'success', url: url };
                                return imgDetails;
                            })
                            .catch((e: any) => {
                                console.log('list item error', e.code);
                            });

                        return item;
                    };

                    const actions = res.items.map(getAllDownloadURLs);
                    const imgResults = Promise.all(actions);

                    imgResults.then((imgList) => {
                        setFileList(imgList);
                    });
                })
                .catch((error) => {
                    console.log('error', error);
                    // Uh-oh, an error occurred!
                });
        }
    }, [props.coverImg]);

    // useEffect(() => () => console.log('imageuploader unmount'), []);

    return (
        <div style={{ marginTop: '20px' }}>
            <ImgCrop {...cropProps}>
                <Dragger {...draggerProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload (10 image limit)</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other band
                        files
                    </p>
                    {isReallyNotEmpty(fileList) && (
                        <p className="ant-upload-text">
                            You can also choose & preview photos from your gallery or delete {DeleteIcon()}
                        </p>
                    )}
                    {uploadError && <p>Upload error occured please retry.</p>}
                    {uploadStart && (
                        <Progress
                            strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                            percent={uploadProgress}
                        />
                    )}
                </Dragger>
            </ImgCrop>
            {props.coverImg || previewImage ? (
                <CenterImgAndMaxWidth100>
                    <TheDivider />
                    <p>Your current image preview</p>
                    <img src={previewImage} />
                </CenterImgAndMaxWidth100>
            ) : (
                ''
            )}
            <Modal
                centered
                footer={null}
                visible={previewVisible}
                title="Previewing cover photo"
                onCancel={() => setPreviewVisible(false)}
            >
                <CenterImgAndMaxWidth100>
                    <img alt="channel cover photo" src={previewImage} />
                </CenterImgAndMaxWidth100>
            </Modal>
        </div>
    );
};

export default ImageUploader;
