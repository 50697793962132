import styled, { css } from 'styled-components';
import { SiderProps } from 'antd/lib/layout/Sider';
import { Layout, Breadcrumb } from 'antd';
const { Header, Sider, Content } = Layout;

interface MenuSiderProps extends SiderProps {
    paddingleft?: string;
    ismobile?: string;
    collapsed?: boolean;
}

const LayoutStyled = styled(Layout)``;

const Layout100HeightStyled = styled(Layout)`
    min-height: 100vh;
`;

const HeaderStyled = styled(Header)`
    @media only screen and (max-width: 600px) {
        &.mobile {
            padding: 0px;
        }
    }
`;

const mobileMenuOpenedOverlay = css`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.2s;
    background: rgba(0, 0, 0, 0.45);
`;

const MenuOpenedContentOverlay = styled.div<MenuSiderProps>`
    transition: all 0.2s;
    ${({ ismobile, collapsed }) => (ismobile === 'true' && !collapsed ? mobileMenuOpenedOverlay : '')};
`;

const ContentStyled = styled(Content)`
    margin: 0 16px;
    padding: 0px 16px;
    @media only screen and (max-width: 600px) {
        margin: 0 5px;
        padding: 0px;
    }
`;

const BreadCrumbAndLangStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    @media only screen and (max-width: 600px) {
        margin: 5px 0;
    }
`;
const BreadcrumbsStyled = styled(Breadcrumb)``;

const SiteLayoutBackground = styled.div`
    background-color: inherit;
    padding: 24px;
    min-height: 360px;
    height: 100%;
    @media only screen and (max-width: 600px) {
        padding: 0px;
        .explore-heading {
            padding: 5px;
        }
        .ant-divider-horizontal {
            margin: 12px 0;
        }
    }
`;

// TODO: change the icon of the menu icon
const ResponsiveMenuIconStyles = {
    // right: 'unset',
    // top: '10px',
    // width: '44px',
    // height: '44px',
    // left: '0',
    // bottom: 'unset',
    // fontsize: '15px',
    // position: 'fixed' as 'fixed',
    background: 'rgba(0, 21, 41, 0.5)',
    borderRadius: '0px 10px 10px 0px',
};

const mobileThemeMenuSider = css`
    position: fixed;
    z-index: 999;
    height: 100%;
`;

const MenuSider = styled(Sider)<MenuSiderProps>`
    transition: all 0.2s;
    ${({ ismobile }) => (ismobile === 'true' ? mobileThemeMenuSider : '')};

    .ant-layout-sider-children {
        overflow: ${({ width }) => (width === '160' ? 'hidden' : '')};
    }
    .ant-menu-item-only-child {
        padding-left: ${({ paddingleft }) => paddingleft} !important;
        text-align: ${({ collapsed }) => (collapsed ? 'center' : 'inherit')};
        span:last-child {
            margin-left: ${({ ismobile }) => (ismobile ? '10px' : '')};
        }
        transition: all 0.2s;
    }
    .ant-layout-sider-zero-width-trigger {
        top: 12px;
    }
`;

const LogoWrapper = styled.div`
    display: block;
    padding: 10px;
`;

const Logo = styled.div<MenuSiderProps>`
    transition: 500ms;
    width: 100%;
    height: ${({ collapsed }) => (collapsed ? 50 : 100)}px;
    min-height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background: inherit;
    background-image: url('https://source.unsplash.com/200x200/?textures-patterns');
`;

export {
    Logo,
    MenuSider,
    LogoWrapper,
    LayoutStyled,
    HeaderStyled,
    ContentStyled,
    BreadcrumbsStyled,
    BreadCrumbAndLangStyled,
    SiteLayoutBackground,
    Layout100HeightStyled,
    MenuOpenedContentOverlay,
    ResponsiveMenuIconStyles,
};
