import styled, { css, createGlobalStyle } from 'styled-components';
import { Divider, Skeleton, Layout, Space } from 'antd';
import { LoaderSvg, RippleLoader } from './imgs';
const { Footer } = Layout;

// url(${RippleLoader}) no-repeat fixed center -6%
const GlobalStyle = createGlobalStyle`
    html {
        background: #001628;
    }
    body {
        color: rgba(255, 255, 255, 0.85);
        @media only screen and (min-width: 600px) {
            font-size: 1.5vw;
        }
        /* background: #001628 url(${RippleLoader}) no-repeat fixed center -6%; */
    }

    .content-page {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .auth-user-page,
    .channel-page,
    .explore-page {
        img {
            width: 100%;
        }
    }

    p {
        iframe {
            background: url(${LoaderSvg}) center center no-repeat;
            background-color: black;
        }
    }


    .video-responsive-wrapper {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;


        iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }
    }
    .anticon.anticon-paper-clip {
        cursor: pointer;
    }
    .anticon-delete svg {
        color: red;
    }
    .ant-breadcrumb > span:last-child a.active,
    .ant-breadcrumb > span:last-child {
        color: steelblue;
    }
    img.antd-img-crop-media {
        width: auto;
    }
    .ant-back-top {
        right: 12px;
        bottom: 25px;
        @media only screen and (max-width: 600px) {
            right: 5px;
            bottom: 50px;
        }
    }
    .inline-input-edit {
        background-color: #000;
    }
`;

const PageWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;

const SkeletonLoader = styled(Skeleton)``;
const SkeletonTitleLoader = styled(Skeleton)`
    opacity: 0.2;
    transition: 200ms;
`;

const SpinLoader = styled(Space)`
    position: absolute;
    top: 50%;
    left: 50%;
`;

const SkeletonAvatarLoader = styled(Skeleton.Avatar)`
    opacity: 1;
    transition: 200ms;
    transform: translateY(15%);
`;

const SkeletonInputLoader = styled(Skeleton.Input)`
    width: 200px;
`;

const TheDivider = styled(Divider)`
    color: #333;
    font-weight: normal;
`;

const BoxShadow = styled.div`
    -webkit-box-shadow: 7px 7px 25px -4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 7px 7px 25px -4px rgba(0, 0, 0, 0.25);
    box-shadow: 7px 7px 25px -4px rgba(0, 0, 0, 0.25);
`;

const TheFooter = styled(Footer)`
    text-align: center;
    .kofi-logo {
        width: 22px;
        height: auto;
    }
`;

export {
    GlobalStyle,
    PageWrapper,
    SkeletonLoader,
    SkeletonAvatarLoader,
    SkeletonTitleLoader,
    SkeletonInputLoader,
    SpinLoader,
    BoxShadow,
    TheDivider,
    TheFooter,
};
