import React from 'react';
import { Box, Email, Image, Item, Span, A, renderEmail } from 'react-html-email';
import { isReallyNotEmpty } from '../Channel/Helpers/ramdaHelpers';

const css = `
img {
  max-width: 700px;
  width: 100%;
}
@media only screen and (max-device-width: 480px) {
  font-size: 20px !important;
}`.trim();

type EmailProps = {
    title: string;
    bodyTitle?: string;
    bodyText?: string;
    img?: { cover?: string; width?: any; height?: any };
    sourceURL: string;
    sourceURLText?: string;
};

export const emailHelper = (props: EmailProps) => {
    const {
        title = 'hello',
        bodyTitle = 'hello there!',
        bodyText = 'hey',
        img,
        sourceURL,
        sourceURLText = 'Checkout post',
    } = props;

    return renderEmail(
        <Email title={title} headCSS={css}>
            <Item>
                <Span fontSize={20}>{bodyTitle}</Span>
            </Item>
            <Item>
                <Box cellSpacing={20} width="100%" style={{ borderTop: '1px solid black' }}>
                    <Item align="center">
                        {isReallyNotEmpty(img) && (
                            <Image
                                alt={title}
                                src={img?.cover}
                                width={img?.width || '100%'}
                                height={img?.height || 'auto'}
                            />
                        )}
                    </Item>
                    <Item>
                        {isReallyNotEmpty(bodyText) && (
                            <Span fontSize={18} color="black" lineHeight={20}>
                                {bodyText}
                            </Span>
                        )}
                        <br />
                    </Item>
                    <Item align="left">
                        {isReallyNotEmpty(sourceURL) && (
                            <Span color="#1890ff" style={{ padding: '5px', border: '2px dotted #1890ff' }}>
                                <A textDecoration="none" href={sourceURL}>
                                    {sourceURLText}
                                </A>
                            </Span>
                        )}
                    </Item>
                    <Item align="right">
                        <Span color="gray" lineHeight={20}>
                            Generated by <A href="http://each1teach.one/">each1teach.one</A>
                        </Span>
                    </Item>
                </Box>
            </Item>
        </Email>,
    );
};
