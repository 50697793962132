import React, { useCallback, useState, useEffect } from 'react';
import { db } from '../../../firebase/firebaseHelpers';

type SnapshotDocProps = {
    metadata: { hasPendingWrites: any };
    data: () => any;
    exists: any;
};

const channelsRef = db.collection('channels');
const contentsRef = db.collection('contents');

const useSubscriptions = () => {
    const [subscriptions, setSubscriptions] = useState<any[]>([]);
    const [userSubscriptions, setUserSubscriptions] = useState([]);

    useEffect(() => {
        if (userSubscriptions.length) {
            const fetch = async () => {
                const _subs = async (sub: any) => {
                    let query = contentsRef
                        .where('currentUser.id', '==', sub.id)
                        .where('public', '==', true)
                        .where('draft', '==', false)
                        .orderBy('createdAt', 'desc')
                        .limit(1);

                    const content = await query.get();

                    let _content;
                    content.forEach((snapshot) => {
                        if (snapshot.exists) {
                            _content = snapshot.data();
                        } else {
                            _content = {};
                        }
                    });
                    return _content;
                };
                const subscriptionsToResolve = userSubscriptions.map(_subs);
                let subsResolved = await Promise.all(subscriptionsToResolve);
                setSubscriptions(subsResolved);
            };
            fetch();
        }
    }, [userSubscriptions]);

    // todo change background colors
    const getSubscriptions = useCallback(
        (chanId: string | undefined) => {
            channelsRef.doc(chanId).onSnapshot(
                (doc: SnapshotDocProps) => {
                    if (doc.exists) {
                        const data = doc.data().subscriptions;
                        return setUserSubscriptions(data);
                    } else {
                        return [];
                    }
                },
                (error) => {
                    console.log('useSubscriptions -> error', error);
                },
            );
        },
        [channelsRef, contentsRef],
    );

    return { subscriptions, getSubscriptions };
};

export default useSubscriptions;
