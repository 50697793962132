import React, { FC, lazy, Suspense, useEffect } from 'react';
// import AppRoutes from './NavigationRoutes/AppRoutes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MainDashboard from './pages/Dashboard/MainDashboard';
import { SpinLoader } from './Global.styled';
import { Spin } from 'antd';

// import gsap from 'gsap';
// import { CSSPlugin } from 'gsap/CSSPlugin';
// // import { CSSRulePlugin } from 'gsap/CSSRulePlugin';

import './App.css';

const App: FC = () => {
    // useEffect(() => {
    //     // Force CSSPlugin to not get dropped during build
    //     if (typeof window !== 'undefined') {
    //         console.log('gsap v', gsap, CSSPlugin);
    //         gsap.registerPlugin(CSSPlugin);
    //     }
    // }, [window]);
    return (
        <Router>
            <Switch>
                {/* <Route
                    name="Zoom Test"
                    path="/zoom/video"
                    component={() => (
                        <Suspense fallback={<div>Loading Zoom...</div>}>
                            <ZoomTest />
                        </Suspense>
                    )}
                    exact
                /> */}
                <Route
                    name="splash"
                    path="*"
                    component={() => (
                        <Suspense
                            fallback={
                                <SpinLoader size="middle">
                                    <Spin size="large" />
                                </SpinLoader>
                            }
                        >
                            <MainDashboard />
                        </Suspense>
                    )}
                />
            </Switch>
            {/* <AppRoutes /> */}
        </Router>
    );
};

export default App;
