import { Button } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './FourZeroFour.scss';

interface Props {}

const FourZeroFour: FC<Props> = () => {
    const { t } = useTranslation(['translation', 'dashboard']);
    const bgImg = 'http://i.giphy.com/sOoqq1XjICptC.gif';

    return (
        <div className="FourZeroFour">
            <div className="bg" style={{ backgroundImage: 'url(' + bgImg + ')' }}></div>
            <div className="code">
                404
                <NavLink className="backHome" to={`/explore`}>
                    <div>/Home</div>
                </NavLink>
            </div>
        </div>
    );
};

export default FourZeroFour;
