import styled, { css } from 'styled-components';
import { Button, Steps } from 'antd';
import { BoxShadow } from '../../Global.styled';
// import { LazyImage } from './Helpers/LazyLoadImg';
const { Step } = Steps;

const CenterImgAndMaxWidth100 = styled.div`
    text-align: center;
    img {
        max-width: 100%;
    }
`;

type NextBtnProps = {
    currentstep?: number | null;
};
const EditorWell = styled(BoxShadow)`
    flex: 1 1 0%;
    margin: 0 auto;
    max-width: 1200px;
    border-radius: 2px;
    background: inherit;
    margin: 40px auto;
`;

const Well = styled(BoxShadow)`
    flex: 1 1 0%;
    margin: 0 auto;
    max-width: 700px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background: inherit;
    padding: 40px;
    margin: 0px auto;
`;

const StepsBtnWrapperStyled = styled.div`
    display: flex;
    max-width: 700px;
    margin: 0 auto;
`;
const StepsStyled = styled(Steps)`
    margin: 16px 0;
`;
const StepStyled = styled(Step)``;
const StepsContentStyled = styled.div`
    padding: 40px;
    @media only screen and (max-width: 600px) {
        padding: 20px 0px;
    }
`;
const StepsActionStyled = styled.div`
    flex: 1;
    text-align: left;
`;
const StepsQuickStyled = styled.div`
    flex: 1;
    text-align: center;
`;
const StepsDraftStyled = styled.div`
    flex: 1;
    text-align: right;
`;

const marginShared = css`
    margin: 0 8px;
`;

const PreviousBtnStyled = styled(Button)`
    ${marginShared}
    @media only screen and (max-width: 600px) {
        margin-left: 0px;
    }
`;

const NextBtnStyled = styled(Button)<NextBtnProps>`
    ${marginShared}
    @media only screen and (max-width: 600px) {
        margin: ${({ currentstep }) => (currentstep ? '5px 0px' : '')};
        margin-left: 0px;
    }
`;
// type CoverImgProps = {
//     yPosition?: number;
// };

// const ChannelCoverImg = styled(LazyImage)<CoverImgProps>`
//     object-position: 50% ${(props) => props.yPosition}%;
//     object-fit: cover;
//     height: 250px;
//     -webkit-box-shadow: inset 0px -20px 60px -20px rgba(0, 0, 0, 0.4);
//     -moz-box-shadow: inset 0px -20px 60px -20px rgba(0, 0, 0, 0.4);
//     box-shadow: inset 0px -20px 60px -20px rgba(0, 0, 0, 0.4);
// `;
// const ChannelSearchBar = styled(Search)`
//     width: 300px;
//     max-width: 100%;
// `;

// const ChannelSelect = styled(Select)`
//     width: inherit;
//     max-width: 300px;
// `;
// const ChannelOption = styled(Option)``;
export {
    Well,
    EditorWell,
    StepStyled,
    StepsStyled,
    StepsQuickStyled,
    StepsDraftStyled,
    PreviousBtnStyled,
    NextBtnStyled,
    // ChannelCoverImg,
    StepsActionStyled,
    StepsContentStyled,
    StepsBtnWrapperStyled,
    CenterImgAndMaxWidth100,
    // ChannelSearchBar,
    // ChannelSelect,
    // ChannelOption,
};
