import { Channel } from './../../../interfaces/Channel';
import { timestamp } from './../../../firebase/firebaseHelpers';

const initValues: Channel = {
  id: '',
  key: '',
  userId: '',
  title: '',
  public: true,
  contents: [],
  lockName: false,
  description: '',
  coverImgUrl: '',
  coverImgPositionY: 50,
  uploadImg: false,
  createdAt: timestamp,
  currentUser: { id: '', name: '', email: '', photoURL: '' },
  donate: { type: 'none', handle: '', img: '' },
  views: 1,
  socialLinks: [
    {
      id: 'beemo',
      label: '@anonymous',
      link: 'https://www.insert_your_social_link.com/',
    },
  ]
};
// todo validate that it's a valid URL
export { initValues }