import React, { FC, useState, useEffect, useCallback } from 'react';
import { once } from 'ramda';

import { PlusOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
// import { db } from '../../firebase/firebaseHelpers';
import { useChannels } from '../../context/Channel/ChannelContext';
import { useUsers } from '../../context/User/UserContext';
import ChannelForm from './ChannelForm';
import { useContent } from '../../context/Content/ContentContext';

import { TheDivider, SkeletonLoader, PageWrapper } from '../../Global.styled';
import ContentCards from './ContentCards';

import { Button, Tooltip, Input, Select } from 'antd';
import {
    ContentSearchandFilterWrapper,
    ContentSearchBar,
    ContentFilterSelect,
    ContentFilterOption,
} from './Content.styled';
// import { ChannelCoverImg } from './Channel.styled';
import { ChannelDataHelper } from './Helpers/AuthUserChannelInitialData';
import { LazyImage } from './Helpers/LazyLoadImg';
import { isReallyEmpty, isReallyNotEmpty } from './Helpers/ramdaHelpers';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { ChannelHeaderWrapper } from '../../pages/Dashboard/Public/ChannelPage.styled';
import PaymentGatewayMain from '../Donate/PaymentGatewayMain';
import { SocialMedia } from '../Links/SocialMedia';
import { CopyToClipboardComp } from './Helpers/CopyToClipboardComp';
import { pubChanURL } from './Helpers/pubChanURL';

const { Option } = Select;

interface Props {
    user: any | null;
}

const filterOptions = [
    { name: 'All', value: 'all' },
    { name: 'Draft', value: 'draft' },
    { name: 'Public', value: 'public' },
    { name: 'Unlisted', value: 'unlisted' },
];
const scrollContainer = 'window';

const AuthUserChannel: FC<Props> = ({ user }) => {
    let history = useHistory();

    const [showChannelForm, setShowChannelForm] = useState(false);
    const [loadingContentsOfPage, setLoadingContentsOfPage] = useState(true);
    const [selectViewOption, setSelectViewOption] = useState('me');
    const [selectFilterViewOption, setFilterViewOption] = useState<string>('all');
    const [filterContentBySearch, setFilterContentBySearch] = useState('');
    const [hasNextPage, setHasNextPage] = useState(false);
    // const [showZoom, setShowZoom] = useState(false);

    const {
        state: { currentUser },
    } = useUsers();

    useEffect(() => {
        if (isReallyEmpty(user) && isReallyEmpty(currentUser)) {
            history.push('/explore');
        }
    }, [user, currentUser]);

    const {
        getChannel,
        // updateChannelContentField,
        state: { currentChannel, loading: loadingChannel },
    } = useChannels();

    const {
        getAllContentByChannelId,
        getMoreChannelContent,
        state: { contentsOfChannel, loading: loadingContent, lastVisibleChannelContent },
    } = useContent();

    const _getAllContentByChannelId = useCallback(
        once(async () => {
            try {
                await getAllContentByChannelId(currentChannel?.id, 'authChan');
            } catch (error) {
                console.log('_getAllContentByChannelId -> error', error);
                setLoadingContentsOfPage(false);
            } finally {
                setTimeout(() => setLoadingContentsOfPage(false), 1000);
            }
        }),
        [getAllContentByChannelId, currentChannel?.id, setLoadingContentsOfPage],
    );

    const channelData = ChannelDataHelper(currentUser);

    const _getChannel = useCallback(
        once(async () => {
            try {
                await getChannel(channelData);
            } catch (error) {
                console.log('_getChannel -> error', error);
                setLoadingContentsOfPage(false);
            } finally {
            }
        }),
        [getChannel, setLoadingContentsOfPage, channelData],
    );

    useEffect(() => {
        let isCancelled = false;
        if (isReallyNotEmpty(currentUser) && !isCancelled) {
            if (currentUser?.id === currentChannel?.currentUser?.id) {
                return;
            } else {
                // set initial channel data
                _getChannel();
            }
        }
        // cleanup
        return () => {
            isCancelled = true;
        };
    }, [currentUser, currentChannel]);

    useEffect(() => {
        let isCancelled = false;
        if (isReallyNotEmpty(currentChannel?.id) && !isCancelled) {
            _getAllContentByChannelId();
        }
        // cleanup
        return () => {
            isCancelled = true;
        };
    }, [currentChannel?.id]);

    useEffect(() => {
        let isCancelled = false;
        const hasNextPage = isReallyNotEmpty(lastVisibleChannelContent?.exists);
        // console.log('hasNextPage', hasNextPage);
        if (hasNextPage && !isCancelled) {
            setHasNextPage(hasNextPage);
        }
        // cleanup
        return () => {
            isCancelled = true;
        };
    }, [lastVisibleChannelContent]);

    const handleLoadMore = useCallback(() => {
        // Fetch get more content
        getMoreChannelContent(currentChannel?.id, 'authChan');
    }, [getMoreChannelContent]);

    const infiniteRef = useInfiniteScroll<any | null>({
        loading: loadingContent,
        hasNextPage,
        onLoadMore: handleLoadMore,
        scrollContainer,
    });

    const handleViewSelect = (value: string) => {
        setSelectViewOption(value);
    };

    const handleFilterSelect = (value: string) => {
        setFilterViewOption(value);
    };

    const onSearchChange = (e: any) => {
        const value = e.target.value.toLowerCase();
        setFilterContentBySearch(value);
    };

    const addContent = () => {
        history.push(`/channel/${currentChannel?.id}/content/add`);
    };

    const filteredContentsOfChannel = () => {
        let filteredContent;
        if (selectFilterViewOption !== 'all' && contentsOfChannel !== null) {
            filteredContent = contentsOfChannel?.filter((content) => {
                switch (selectFilterViewOption) {
                    case 'draft':
                        return content.draft === true;
                    case 'public':
                        return content.public === true;
                    case 'unlisted':
                        return content.public === false;
                    default:
                        break;
                }
            });
        } else {
            filteredContent = contentsOfChannel;
        }
        // filter all by title search
        if (filterContentBySearch !== '' && filteredContent !== null) {
            filteredContent = filteredContent?.filter((content) => {
                let title = content.title.toLowerCase();
                if (title.includes(filterContentBySearch)) {
                    return content;
                }
            });
        }

        return filteredContent;
    };

    return (
        <PageWrapper className="auth-user-page">
            {loadingChannel ? (
                <SkeletonLoader active />
            ) : (
                <>
                    <h2> Hey {currentUser?.name} 👋</h2>
                    <CopyToClipboardComp
                        textToCopy={`${pubChanURL}/${currentChannel?.key}`}
                        tooltipText={`copy your public channel url to clipboard.`}
                    />
                    <div style={{ textAlign: 'right' }}>
                        <Select defaultValue={`me`} onChange={handleViewSelect}>
                            <Option value="public">view as public</Option>
                            <Option value="me">view as me</Option>
                            {/* <Option value="other names under your account">some name</Option> */}
                        </Select>
                    </div>
                    <TheDivider orientation="center">
                        <span style={{ marginRight: '5px' }}>Customize your channel</span>
                        <Tooltip title={showChannelForm ? 'Back' : 'Edit'}>
                            <Button
                                id="channel-form"
                                shape="circle"
                                icon={showChannelForm ? <RollbackOutlined /> : <EditOutlined />}
                                onClick={() => setShowChannelForm(!showChannelForm)}
                            />
                        </Tooltip>
                    </TheDivider>
                    {showChannelForm ? (
                        <ChannelForm data={currentChannel} setShowChannelForm={setShowChannelForm} />
                    ) : (
                        <>
                            {currentChannel?.coverImgUrl && (
                                <LazyImage
                                    type="channel"
                                    yPosition={currentChannel.coverImgPositionY}
                                    src={currentChannel.coverImgUrl}
                                    alt={'cover image'}
                                />
                            )}
                            <ChannelHeaderWrapper>
                                <img className="userImg" src={currentChannel?.currentUser?.photoURL} />
                                <div className="wrapper">
                                    <div className="first-col">
                                        {/* <div className="subscribers">subscribers - 290</div> */}
                                        <div className="views">{currentChannel?.views} - channel views</div>
                                        <div className="title">{currentChannel?.title}</div>
                                        <div className="desc">{currentChannel?.description}</div>
                                    </div>
                                    <div className="second-col">
                                        <div className="donate">
                                            <PaymentGatewayMain donate={currentChannel?.donate} />
                                        </div>
                                        <div className="social-links">
                                            <SocialMedia socialLinks={currentChannel?.socialLinks} />
                                        </div>
                                    </div>
                                </div>
                            </ChannelHeaderWrapper>
                        </>
                    )}
                </>
            )}

            {loadingChannel ? (
                <SkeletonLoader active />
            ) : (
                <TheDivider orientation="center">
                    <span style={{ marginRight: '5px' }}>Let's start creating</span>
                    <Tooltip title="Add Content">
                        <Button shape="circle" icon={<PlusOutlined />} onClick={addContent} />
                    </Tooltip>
                </TheDivider>
            )}

            {loadingContentsOfPage || loadingContent || loadingChannel ? (
                <>
                    <SkeletonLoader active />
                    <SkeletonLoader active />
                </>
            ) : !loadingContentsOfPage && isReallyEmpty(contentsOfChannel) ? (
                <>
                    <p>Currently no content, let's create some!</p>
                </>
            ) : (
                <div ref={infiniteRef}>
                    {!loadingContentsOfPage && isReallyNotEmpty(contentsOfChannel) && (
                        <ContentSearchandFilterWrapper top={5}>
                            <ContentSearchBar
                                placeholder="Search your content by title"
                                onChange={(e) => onSearchChange(e)}
                                allowClear
                            />

                            <ContentFilterSelect
                                defaultValue={`All`}
                                onChange={(value: any) => handleFilterSelect(value)}
                            >
                                {filterOptions.map((option) => (
                                    <ContentFilterOption key={option.name} value={option.value}>
                                        {option.name}
                                    </ContentFilterOption>
                                ))}
                            </ContentFilterSelect>
                        </ContentSearchandFilterWrapper>
                    )}
                    <ContentCards selectViewOption={selectViewOption} contentsOfChannel={filteredContentsOfChannel()} />
                </div>
            )}
        </PageWrapper>
    );
};

export default AuthUserChannel;

// Add compact view version
// Upload image on cards
// Be able to segment out draft / live / unlisted
// a symbol on the image card with live or unlisted
// draft can be a separate column
