import React, { FC, useState, useEffect, useCallback } from 'react';
import {
    HomeOutlined,
    VideoCameraOutlined,
    BulbOutlined,
    FolderAddOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
    MenuSider,
    LogoWrapper,
    Logo,
    ResponsiveMenuIconStyles,
    MenuOpenedContentOverlay,
} from './MainDashboard.styled';
import { useTranslation } from 'react-i18next';

interface Props {
    user?: any;
    location?: any;
}

const MainDashboardSideMenu: FC<Props> = ({ user, location }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [breakpoint, setBreakpoint] = useState(false);
    const { t } = useTranslation(['dashboard']);

    const onCollapse = useCallback(
        (collapsed: boolean, type: string) => {
            // console.log(collapsed, type);
            setCollapsed(collapsed);
            return;
        },
        [collapsed, setCollapsed],
    );

    const onBreakpoint = useCallback(
        (breakpoint: any) => {
            setBreakpoint(breakpoint);
            return;
        },
        [breakpoint, setBreakpoint],
    );

    return (
        <>
            <MenuSider
                // trigger={null}
                id="app-menu-sider"
                collapsible
                breakpoint="sm"
                ismobile={breakpoint.toString()}
                collapsed={collapsed}
                onCollapse={onCollapse}
                defaultCollapsed={true}
                onBreakpoint={onBreakpoint}
                width={breakpoint ? '160' : '200'}
                collapsedWidth={breakpoint ? '0' : '80'}
                paddingleft={breakpoint ? '24px' : collapsed ? 'none' : '24px'}
                zeroWidthTriggerStyle={ResponsiveMenuIconStyles}
            >
                <LogoWrapper>
                    <Logo collapsed={collapsed} className="logo" />
                </LogoWrapper>
                <Menu selectedKeys={[location.pathname]} theme="dark" defaultSelectedKeys={['/explore']} mode="inline">
                    <Menu.Item key="/explore">
                        <NavLink to={`/explore`}>
                            <HomeOutlined />
                            <span>{t('dashboard:default.menuText.explore')}</span>
                        </NavLink>
                    </Menu.Item>

                    {!user && (
                        <Menu.Item key="/content">
                            <NavLink to={`/content`}>
                                <PlusCircleOutlined />
                                <span>{t('dashboard:default.menuText.content')}</span>
                            </NavLink>
                        </Menu.Item>
                    )}

                    <Menu.Item key="/todos">
                        <NavLink to={`/todos`}>
                            <BulbOutlined />
                            <span>
                                {user
                                    ? t('dashboard:default.menuText.todos.auth')
                                    : t('dashboard:default.menuText.todos.guest')}
                            </span>
                        </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item key="/trending">
                        <NavLink to={`/trending`}>
                            <RiseOutlined />
                            <span>Trending</span>
                        </NavLink>
                    </Menu.Item> */}

                    <Menu.Item key="/channel">
                        {user && (
                            <NavLink to={`/channel`}>
                                <VideoCameraOutlined />
                                <span>{t('dashboard:default.menuText.channel')}</span>
                            </NavLink>
                        )}
                        {/* <SkeletonTitleLoader paragraph={false} title loading={!user && loadingApp} /> */}
                    </Menu.Item>

                    <Menu.Item key="/subscriptions">
                        {user && (
                            <NavLink to={`/subscriptions`}>
                                <FolderAddOutlined />
                                <span>Subscriptions</span>
                            </NavLink>
                        )}
                    </Menu.Item>

                    {/* Revisit Zoom API */}
                    {/* <Menu.Item key="/zoom">
                        {user ? (
                            <NavLink to={`/zoom`}>
                                <FolderAddOutlined />
                                <span>Zoom it</span>
                            </NavLink>
                        ) : (
                            <SkeletonTitleLoader loading={loadingUser} paragraph={false} title active />
                        )}
                    </Menu.Item> */}
                </Menu>
            </MenuSider>

            <MenuOpenedContentOverlay
                onClick={() => setCollapsed(true)}
                ismobile={breakpoint.toString()}
                collapsed={collapsed}
            />
        </>
    );
};

export default MainDashboardSideMenu;
