import { Avatar, Comment, Tooltip } from 'antd';
import React, { FC, useState, createElement } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { ToDate } from '../../Channel/Helpers/ToDate';
import { DislikeOutlined, LikeOutlined, DislikeFilled, LikeFilled } from '@ant-design/icons';

type MessageProps = {
    currentUser?: firebase.User | null | undefined;
    message: {
        uid?: string;
        text?: string;
        photoURL?: string;
        createdAt?: any;
        displayName?: string;
    };
};

// TODO: get likes and dislikes to work and link to channel avatars
// TODO: make cards into masonary styled layout
const Message: FC<MessageProps> = ({ currentUser, message }) => {
    const [likes, setLikes] = useState(0);
    const [dislikes, setDislikes] = useState(0);
    const [action, setAction] = useState<string | null>(null);

    const { text, uid, photoURL, createdAt, displayName } = message;

    let convertedDate = null;
    if (createdAt) {
        convertedDate = ToDate(createdAt);
    }

    const messageClass = uid === currentUser!.uid ? 'sent' : 'received';

    const like = () => {
        setLikes(1);
        setDislikes(0);
        setAction('liked');
    };

    const dislike = () => {
        setLikes(0);
        setDislikes(1);
        setAction('disliked');
    };

    const actions = [
        <Tooltip key="comment-basic-like" title="Like">
            <span onClick={like}>
                {createElement(action === 'liked' ? LikeFilled : LikeOutlined)}
                <span className="comment-action">{likes}</span>
            </span>
        </Tooltip>,
        <Tooltip key="comment-basic-dislike" title="Dislike">
            <span onClick={dislike}>
                {createElement(action === 'disliked' ? DislikeFilled : DislikeOutlined)}
                <span className="comment-action">{dislikes}</span>
            </span>
        </Tooltip>,
        // <span key="comment-basic-reply-to">Reply to</span>,
    ];

    return (
        <>
            <div className={`message ${messageClass}`}>
                <Comment
                    actions={actions}
                    author={displayName}
                    avatar={<Avatar src={photoURL} alt={displayName} />}
                    content={<p>{text}</p>}
                    datetime={
                        <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                            <span>{convertedDate && <Moment fromNow>{convertedDate}</Moment>}</span>
                        </Tooltip>
                    }
                />
            </div>
            {/* <div className={`message ${messageClass}`}>
                <img src={photoURL} />
                <p>{text}</p>
            </div> */}
        </>
    );
};

export default Message;
