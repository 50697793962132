interface FbDate {
    seconds: number;
    nanoseconds: number;
}

export const ToDate = (date: FbDate) => {
    const toMillis = () => 1e3 * date.seconds + date.nanoseconds / 1e6;
    return new Date(toMillis());
};

// Date.prototype.toMillis = function () {
//     return 1e3 * this.seconds + this.nanoseconds / 1e6;
// };
// Date.prototype.toDate = function () {
//     return new Date(this.toMillis());
// };