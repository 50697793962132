import React, { FC, useEffect, useRef, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db, fbTimestamp } from '../../../firebase/firebaseHelpers';
import Message from '../Message/Message';

interface RoomProps {
    currentUser: firebase.User | undefined;
}

const Room = ({ currentUser }: RoomProps) => {
    const scrollDummy = useRef<null | HTMLElement>(null);
    const messagesRef = db.collection('messages');
    const query = messagesRef.orderBy('createdAt').limitToLast(25);

    const [messages] = useCollectionData(query, { idField: 'id' });

    const [formValue, setFormValue] = useState('');

    const setScroll = () => scrollDummy?.current!.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        if (currentUser && scrollDummy?.current!) {
            setScroll();
        }
    }, [currentUser, scrollDummy?.current!]);

    useEffect(() => {
        return () => setScroll();
    }, []);

    const sendMessage = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (currentUser) {
            const { uid, photoURL, displayName } = currentUser;

            await messagesRef.add({
                text: formValue,
                createdAt: fbTimestamp,
                uid,
                photoURL,
                displayName,
            });

            scrollDummy?.current!.scrollIntoView({ behavior: 'smooth' });
            setFormValue('');
        }
    };

    return (
        <div id="chat-main">
            <main>
                {messages &&
                    messages.map((msg: any) => <Message key={msg.id} currentUser={currentUser} message={msg} />)}
                <span ref={scrollDummy}></span>
            </main>
            <form id="chat-form" onSubmit={sendMessage}>
                <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="say something" />

                <button type="submit" disabled={!formValue}>
                    send
                </button>
            </form>
        </div>
    );
};

export { Room };
