import React, { FC, useState, useEffect } from 'react';
import { WrappedComponentProps } from 'react-with-firebase-auth';
import AuthHOC from '../../firebase/firebaseAuth';
import styled from 'styled-components';

import {
    GoogleCircleFilled,
    SettingOutlined,
    UserOutlined,
    LogoutOutlined,
    SmileOutlined,
    BellTwoTone,
    BellOutlined,
} from '@ant-design/icons';
import { Menu, Avatar, Badge, notification, Button } from 'antd';
import { useUsers } from '../../context/User/UserContext';
import { LoadingAvatar } from '../GlobalLoader';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isReallyEmpty, isReallyNotEmpty, isPathname } from '../Channel/Helpers/ramdaHelpers';
import { filter } from 'ramda';
import useNotifications from '../../context/Channel/useNotifications';
import Moment from 'react-moment';
import { ToDate } from '../Channel/Helpers/ToDate';
const { SubMenu } = Menu;

interface Props extends WrappedComponentProps {}

type UserAvatarProps = {
    userImg?: string | undefined;
};

type UserNotificationsProps = {
    currentAuthUser?: any;
    notifications?: any;
    notifySuberChecked?: (chanId: string, suberId: any) => Promise<any>;
    goTo?: (val: string) => void;
};

const MenuWrapper = styled(Menu)`
    display: flex;
    place-content: flex-end;
`;
const MainMenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
// todo move styles out of here to separate folder
const NotificationsMenu = styled.div`
    .notification-icon {
        transition: color 0.3s;
        color: rgba(255, 255, 255, 0.65);
        transform: scaleX(-1);
        padding: 0 10px;
        cursor: pointer;
        font-size: 18px;
        &:hover {
            transform: scaleX(-1) rotate(-10deg);
            color: rgba(255, 255, 255, 1);
        }
    }
`;

const openNotification = (currentAuthUser: any, notifications: any, notifySuberChecked: any, goTo: any) => {
    if (Object.keys(notifications).length === 0) {
        return;
    }
    // console.log('openNotification -> notifications', notifications);
    Object.keys(notifications).map((idProperty) => {
        // notifications[idProperty]
        // todo: pass in time stamp
        const {
            name,
            notifiedAt,
            channelData: { key, photoURL, title },
        } = notifications[idProperty];

        const convertedDate = ToDate(notifiedAt);

        const Message = () => (
            <div style={{ margin: '0px' }}>
                <img src={photoURL} style={{ width: '25px', borderRadius: '100%' }} alt="profile img" /> {name}{' '}
            </div>
        );
        const Desc = () => (
            <div style={{ marginLeft: '30px' }}>
                <span>
                    subscribed to you <Moment fromNow>{convertedDate}</Moment>
                </span>
                <br />
                <span>
                    Checkout their
                    <Button type="link" onClick={() => goTo(key)} style={{ padding: '0px 5px' }}>
                        channel
                    </Button>
                    🖖
                </span>
            </div>
        );
        notification.open({
            key: name,
            placement: 'topRight',
            top: 50,
            message: Message(),
            description: Desc(),
            onClick: async () => {
                await notifySuberChecked(currentAuthUser?.uid, idProperty);
                notification.close(name);
            },
        });
    });
};

// type NotificationsProp = {
//     notificationCheck: boolean;
// };

const UserAvatar: FC<UserAvatarProps> = ({ userImg }) => {
    return (
        <span className="avatar-item">
            {isReallyEmpty(userImg) ? (
                <Avatar shape="circle" icon={<SmileOutlined />} />
            ) : (
                <Avatar shape="circle" src={userImg} />
            )}
        </span>
    );
};

const UserNotifications: FC<UserNotificationsProps> = ({
    currentAuthUser,
    notifications = {},
    notifySuberChecked,
    goTo,
}) => {
    const isFalse = (_: any) => _.notificationCheck === false;
    const _notes = filter(isFalse)(notifications);
    const numberOfNotifications = Object.keys(_notes).length;

    return (
        <span className="avatar-item">
            {isReallyEmpty(currentAuthUser) ? null : (
                <div onClick={() => openNotification(currentAuthUser, _notes, notifySuberChecked, goTo)}>
                    <Badge count={numberOfNotifications} overflowCount={9} size="small" offset={[-10, 0]}>
                        {numberOfNotifications > 0 && (
                            <BellTwoTone
                                twoToneColor={'gold'}
                                className="notification-icon"
                                title={'Check out your notifications'}
                            />
                        )}
                        {numberOfNotifications === 0 && (
                            <BellOutlined className="notification-icon" title={'No news today.'} />
                        )}
                    </Badge>
                </div>
            )}
        </span>
    );
};

const NavMenu: FC<Props> = ({ user, signInWithGoogle, signOut }) => {
    const history = useHistory();
    const location = useLocation();

    const matchExplore = useRouteMatch({
        path: '/explore',
        sensitive: true,
        strict: true,
    });

    const goTo = (key: string) => {
        if (matchExplore?.isExact || matchExplore === null) {
            return history.push(`explore/${key}`);
        }
        // for explore/:channelKey
        return history.push(`${key}`);
    };

    const {
        getUser,
        logUserOut,
        state: { loading: loadingUser },
    } = useUsers();

    const { notifications, getNotifications, notifySuberChecked } = useNotifications();

    const [avatarUrl, setAvatarUrl] = useState('');
    const [action, setAction] = useState('');
    // const [notifications, setNotifications] = useState({});

    const { t } = useTranslation(['dashboard']);

    const currentAuthUser = user;

    useEffect(() => {
        if (isReallyEmpty(user)) {
            logUserOut();
            if ((isPathname('/channel', history) || isPathname('/user-settings', history)) && action === 'signout') {
                setTimeout(() => history.push('/explore'), 0);
            }
        }

        if (isReallyNotEmpty(user?.photoURL)) {
            const url = user?.photoURL || '';
            setAvatarUrl(url);
        }
        // check auth of user and check if user is in firestore already, if not add user
        const fetchUser = async () => {
            if (isReallyNotEmpty(user)) {
                await getUser({ id: user?.uid, name: user?.displayName, ...user });

                // todo set listener to update
                getNotifications(currentAuthUser?.uid);
            }
        };
        fetchUser();
    }, [user]);

    const onSignIn = () => signInWithGoogle();
    const onSignOut = () => {
        setAction('signout');
        signOut();
    };

    const theMission = () => {
        history.push(`/mission`);
    };

    const goToYourChannel = () => {
        history.push(`/channel`);
    };

    const userSettings = () => {
        history.push(`/user-settings`);
    };

    const Avatar = (
        <span className="submenu-title-wrapper">
            <UserAvatar userImg={avatarUrl} />
        </span>
    );

    const Notifications = () => (
        <UserNotifications
            currentAuthUser={currentAuthUser}
            notifications={notifications}
            notifySuberChecked={notifySuberChecked}
            goTo={goTo}
        />
    );

    return (
        <MainMenuWrapper>
            <NotificationsMenu>
                <Notifications />
            </NotificationsMenu>
            <MenuWrapper
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={['signin']}
                selectedKeys={[location.pathname]}
            >
                {user && isReallyNotEmpty(user) ? (
                    <SubMenu title={loadingUser ? LoadingAvatar : Avatar}>
                        <Menu.ItemGroup title={t('dashboard:default.navigationText.account')}>
                            <Menu.Item key="/channel" onClick={goToYourChannel}>
                                <UserOutlined /> {t('dashboard:default.navigationText.channel')}
                            </Menu.Item>
                            <Menu.Item key="/user-settings" onClick={userSettings}>
                                <SettingOutlined /> {t('dashboard:default.navigationText.settings')}
                            </Menu.Item>
                            <Menu.Item key="signout" onClick={onSignOut}>
                                <LogoutOutlined /> <span>{t('dashboard:default.navigationText.signOut')}</span>
                            </Menu.Item>
                        </Menu.ItemGroup>
                        <Menu.ItemGroup title={t('dashboard:default.navigationText.info')}>
                            <Menu.Item key="/mission" onClick={theMission}>
                                <span>{t('dashboard:default.navigationText.mission')}</span>
                            </Menu.Item>
                        </Menu.ItemGroup>
                    </SubMenu>
                ) : (
                    <Menu.Item key="signin" onClick={onSignIn}>
                        <GoogleCircleFilled />
                        <span>{t('dashboard:default.navigationText.signIn')}</span>
                    </Menu.Item>
                )}
            </MenuWrapper>
        </MainMenuWrapper>
    );
};

export default AuthHOC(NavMenu);
