import React, { FC, useState, useEffect, ReactNode } from 'react';
import {
    StepsStyled,
    StepStyled,
    StepsContentStyled,
    StepsActionStyled,
    StepsDraftStyled,
    StepsBtnWrapperStyled,
    StepsQuickStyled,
    PreviousBtnStyled,
    NextBtnStyled,
} from '../Channel.styled';

interface Props {
    isValid: boolean;
    first: ReactNode;
    second: ReactNode;
    last: ReactNode;
    saveAsDraftBtn: ReactNode;
    submitBtns: ReactNode;
    quickSave: ReactNode | string;
}

const FormSteps: FC<Props> = ({ isValid, first, second, last, saveAsDraftBtn, submitBtns, quickSave }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        {
            title: 'Some basic details',
            content: first,
        },
        {
            title: 'Lets start creating',
            content: second,
        },
        {
            title: 'Finalize',
            content: last,
        },
    ];

    const next = () => {
        const current = currentStep + 1;
        setCurrentStep(current);
        return;
    };

    const prev = () => {
        const current = currentStep - 1;
        setCurrentStep(current);
        return;
    };

    return (
        <div>
            <StepsStyled current={currentStep}>
                {steps.map((item) => (
                    <StepStyled key={item.title} title={item.title} />
                ))}
            </StepsStyled>
            <StepsContentStyled className="steps-content">{steps[currentStep].content}</StepsContentStyled>
            <StepsBtnWrapperStyled>
                <StepsActionStyled className="steps-action">
                    {currentStep > 0 && <PreviousBtnStyled onClick={() => prev()}>Previous</PreviousBtnStyled>}
                    {currentStep < steps.length - 1 && (
                        <NextBtnStyled
                            currentstep={currentStep}
                            type="primary"
                            onClick={() => next()}
                            disabled={!isValid}
                        >
                            Next
                        </NextBtnStyled>
                    )}
                    {currentStep === steps.length - 1 && <>{submitBtns}</>}
                </StepsActionStyled>
                {currentStep !== steps.length - 1 && (
                    <StepsQuickStyled className="steps-action">{quickSave}</StepsQuickStyled>
                )}
                <StepsDraftStyled className="steps-action">{saveAsDraftBtn}</StepsDraftStyled>
            </StepsBtnWrapperStyled>
        </div>
    );
};

export default FormSteps;

{
    /* <Button type="primary" onClick={() => message.success('Processing complete!')}>
                            Done
                        </Button> */
}
