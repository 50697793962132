export const RespIframe = (iframesTiny = [], parentEl = null) => {
    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            }, wait);
            if (immediate && !timeout) func.apply(context, args);
        };
    }

    function forEachIframe(callback) {
        if (iframes.length > 0) {
            for (var i = 0; i < iframes.length; i++) {
                var iframe = iframes[i];
                if (iframe.className.indexOf('dont-resize') !== -1) {
                    iframes.splice(i, 1);
                } else {
                    callback(iframe);
                }
            }
            iframe = undefined;
        }
    }

    function resizeIframes() {
        forEachIframe(function (iframe) {
            if (iframe.length === 0) {
                return;
            }
            var maxWidth =
                iframesTiny.length !== 0
                    ? parentEl.getBoundingClientRect().width
                    : iframe.parentElement.getBoundingClientRect().width;

            var ratio = iframe.height / iframe.width,
                height = maxWidth * ratio;
            iframe.style.height = height + 'px';
            iframe.style.width = '100%';
            // iframe.classList.add('dont-resize');
        });
    }

    // var iframeDoms = document.getElementsByTagName('iframe'),
    var iframeDoms = document.querySelectorAll('.content-page-markup iframe'),
        iframes = Array.prototype.slice.call([...iframeDoms, ...iframesTiny]);

    window.onload = resizeIframes();

    const resize = debounce(resizeIframes, 100);

    // Subscribe
    window.addEventListener('resize', resize);
    // Unsubscribe
    const unsubscribe = () => {
        return window.removeEventListener('resize', resize);
    };

    return {
        unsubscribe: unsubscribe,
    };
};
