import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, message, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

interface Props {
    textToCopy: string | any;
    buttonText?: string | null;
    tooltipText?: string | null;
}

const CopyToClipboardComp: FC<Props> = ({ textToCopy, buttonText, tooltipText }) => {
    return (
        <div className="copy-channel-link">
            <CopyToClipboard text={textToCopy}>
                <Tooltip title={tooltipText}>
                    <Button
                        id="copy-text"
                        shape="circle"
                        icon={<CopyOutlined />}
                        onClick={() => message.success(`Copied ${textToCopy}`)}
                    ></Button>
                </Tooltip>
            </CopyToClipboard>
            <span>{buttonText ? buttonText : ''}</span>{' '}
        </div>
    );
};

export { CopyToClipboardComp };
