const categories = [
  'accidents & emergencies',
  'animals & pets',
  'babies, kids, & family',
  'business & entrepreneurs',
  'celebrations & events',
  'community & neighbors',
  'competitions & pageants',
  'creative arts, music & film',
  'dreams, hopes & wishes',
  'education & learning',
  'environment',
  'funerals & memorials',
  'medical, illness & healing',
  'missions, faith & church',
  'sports, team & clubs',
  'travel & adventure',
  'volunteer & service',
  'weddings & honeymoons',
  'other'
];

export { categories }