import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

interface Props {
    type?: 'content' | 'channel' | string;
    src?: string;
    alt?: string;
    yPosition?: number;
    className?: string;
}

type CoverImgProps = {
    yPosition?: number;
};

const placeHolder =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';

const ReusableImgStyles = styled.img`
    display: block;
    @keyframes loaded {
        0% {
            opacity: 0.1;
        }
        100% {
            opacity: 1;
        }
    }
    /* // I use utilitary classes instead of props to avoid style regenerating */
    &.loaded:not(.has-error) {
        animation: loaded 300ms ease-in-out;
    }
    &.has-error {
        /* // fallback to placeholder image on error */
        content: url(${placeHolder});
    }
`;

const ChannelCoverImg = styled(ReusableImgStyles)<CoverImgProps>`
    object-position: 50% ${(props) => props.yPosition}%;
    object-fit: cover;
    height: 250px !important;
    -webkit-box-shadow: inset 0px -20px 60px -20px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: inset 0px -20px 60px -20px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0px -20px 60px -20px rgba(0, 0, 0, 0.4);
`;

const ContentCoverImg = styled(ReusableImgStyles)`
    object-fit: cover;
    min-height: 200px;
    max-height: 200px;
    border-radius: 2px;
    @media only screen and (max-width: 600px) {
        &.public {
            min-height: 120px;
            max-height: 120px;
        }
    }
    transition: 500ms;
    filter: contrast(80%);
    &:hover {
        /* opacity: 0.9; */
        transition: 500ms;
        filter: contrast(100%);
    }
`;

const Image = styled(ReusableImgStyles)`
    height: 100%;
    width: 100%;
`;

export const LazyImage: FC<Props> = ({ type, src, alt, yPosition, className }) => {
    const [imageSrc, setImageSrc] = useState<any>(placeHolder);
    const [imageRef, setImageRef] = useState<any>();

    const onLoad = (event: any) => {
        event?.target?.classList.add('loaded');
    };

    const onError = (event: any) => {
        event.target.classList.add('has-error');
    };

    useEffect(() => {
        let observer: any;
        let didCancel = false;

        if (imageRef && imageSrc !== src) {
            if (IntersectionObserver) {
                observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                                setImageSrc(src);
                                observer.unobserve(imageRef);
                            }
                        });
                    },
                    {
                        threshold: 0.01,
                        rootMargin: '75%',
                    },
                );
                observer.observe(imageRef);
            } else {
                // Old browsers fallback
                setImageSrc(src);
            }
        }
        return () => {
            didCancel = true;
            // on component cleanup, we remove the listner
            if (observer && observer.unobserve) {
                observer.unobserve(imageRef);
            }
        };
    }, [src, imageSrc, imageRef]);

    let ImageType;
    const defaultProps = {
        className: className || '',
        ref: setImageRef,
        src: imageSrc,
        alt: alt,
        onLoad: onLoad,
        onError: onError,
    };

    if (type === 'channel') {
        ImageType = <ChannelCoverImg yPosition={yPosition} {...defaultProps} />;
    } else if (type === 'content') {
        ImageType = <ContentCoverImg {...defaultProps} />;
    } else {
        ImageType = <Image {...defaultProps} />;
    }
    return ImageType;
};
