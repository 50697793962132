import { Button, Divider } from 'antd';
import React, { FC, useState, useEffect, useRef } from 'react';
import * as ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {}

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean'],
    ],
};

const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
];

const ContentFormPub: FC<Props> = () => {
    const [editorVal, setEditorVal] = useState({ text: 'Start here...' });
    const editorRef = useRef<any>(null);

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current?.focus();
        }
    }, [editorRef]);

    const handleChange = (value: any) => {
        setEditorVal({ text: value });
    };

    const handleSave = () => {
        console.log('savedddd', editorVal);
    };
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '1200px', margin: '0 auto' }}>
                <ReactQuill
                    ref={editorRef}
                    theme="snow"
                    style={{ height: '500px', display: 'block' }}
                    value={editorVal.text}
                    onChange={handleChange}
                    defaultValue={`Start here...`}
                    modules={modules}
                    formats={formats}
                />
                <div style={{ marginTop: '75px', textAlign: 'right' }}>
                    <Button disabled type="dashed" onClick={handleSave}>
                        Save - disbaled for now :)
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ContentFormPub;
