import React, { FC } from 'react';
import { Table, RemoveRowButton, Input } from 'formik-antd';
import { DeleteOutlined } from '@ant-design/icons';
import { SocialLinks } from '../../../interfaces';
import { AddRowButtonStyled, ValidationMsg } from './SocialLinkForm.styled';
import isURL from 'validator/es/lib/isURL';

const columnData = [
    {
        title: 'Label or Handle',
        key: 'label',
        render: (text: any, record: any, i: any) => (
            <Input style={{ border: 'none' }} name={`socialLinks.${i}.label`} />
        ),
    },
    {
        title: 'URL Link',
        key: 'link',
        render: (text: any, record: any, i: any) => {
            const onVal = (text: any) => isURL(text?.link);

            return (
                <>
                    <Input style={{ border: 'none' }} name={`socialLinks.${i}.link`} />
                    {!onVal(text) && <ValidationMsg>this is not a vlid URL, please update</ValidationMsg>}
                </>
            );
        },
    },
    {
        key: 'actions',
        render: (text: any, record: any, index: number) => (
            <RemoveRowButton style={{ border: 'none' }} icon={<DeleteOutlined />} name="socialLinks" index={index} />
        ),
    },
];

interface Props {
    socialLinks?: SocialLinks[];
}

export const SocialLinkTable: FC<Props> = ({ socialLinks }) => {
    const newRow = () => ({
        id: 'beemo-' + Math.random(),
        label: 'Handle or Label',
        link: 'https://...',
    });

    return (
        <>
            <AddRowButtonStyled name="socialLinks" createNewRow={newRow}>
                + Social Link{`${socialLinks && socialLinks.length > 0 ? 's' : ''}`}
            </AddRowButtonStyled>
            <Table
                name="socialLinks"
                rowKey={(row, index) => '' + row.id}
                size="small"
                pagination={false}
                columns={columnData}
            />
        </>
    );
};
