import React from 'react';

const sliderWrapperStyles = {
    margin: '0 auto',
    width: '90%',
};

const sliderMarks = {
    0: {
        style: {
            color: 'grey',
        },
        label: <strong>0%</strong>,
    },
    50: {
        style: {
            color: '#3498f3',
        },
        label: <strong>50%</strong>,
    },
    100: {
        style: {
            color: '#f50',
        },
        label: <strong>100%</strong>,
    },
};

export { sliderWrapperStyles, sliderMarks };
