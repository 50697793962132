import React, { FC, useState, forwardRef, useRef, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAuth, signInWithGoogle } from '../../firebase/firebaseConfig';
import { ChatRoom, ChatToggleCaret } from './index.styled';
import { Room } from './Room/Room';
import { DownOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { Controls, PlayState, Timeline, Tween } from 'react-gsap';

import './chat.scss';

const SignIn = () => {
    return (
        <Button size={'middle'} type={'dashed'} onClick={signInWithGoogle}>
            Sign-in
        </Button>
    );
};

const Chat = () => {
    const [user] = useAuthState(firebaseAuth);
    const [toggle, setToggle] = useState(false);
    const [gsapPlayState, setGsapPlayState] = useState(false);
    const toggleWrapperRef = useRef(null);

    useEffect(() => {
        setGsapPlayState(true);
    }, []);

    const toggleChat = () => {
        setToggle(!toggle);
        setGsapPlayState(false);
    };
    const contentOpen = <div style={{ backgroundColor: '#011529', color: 'white' }}>{`Expand chat`}</div>;
    const contentClose = <div style={{ backgroundColor: '#011529', color: 'white' }}>{`Minimize chat`}</div>;

    const CloseChat = () => (
        <Popover id="popover-caret" content={contentClose}>
            {toggle && <DownOutlined onClick={toggleChat} style={{ padding: '10px', color: 'white' }} />}
        </Popover>
    );

    const ChatRoomComponent = () => (
        <ChatRoom toggle={toggle}>
            <header style={{ textAlign: 'right' }}>
                <CloseChat />
            </header>
            <section>{user ? <Room currentUser={user} /> : <SignIn />}</section>
        </ChatRoom>
    );

    type PulsingProps = {
        children: React.ReactNode;
    };

    const shadowColor = 'rgb(245 245 245)';
    const PulsingChatWrapper: FC<PulsingProps> = ({ children }) => (
        <Tween
            to={{
                boxShadow: `5px 5px 15px 5px #FF8080, -9px 5px 15px 5px #FFE488, -7px -5px 15px 5px #8CFF85, 12px -5px 15px 5px #80C7FF, 12px 10px 15px 7px #E488FF, -10px 10px 15px 7px #FF616B, -10px -7px 27px 1px #8E5CFF, 5px 5px 15px 5px rgba(0,0,0,0)`,
            }}
            repeat="1"
            yoyo={true}
            duration={3}
            playState={gsapPlayState ? PlayState.play : PlayState.stop}
            ease="elastic.out(0.2, 0.1)"
        >
            {children}
        </Tween>
    );
    // console.log("Chat -> PulsingChatWrapper", PulsingChatWrapper)
    const ChatTogglerComponent = forwardRef((props, ref: any) => (
        <ChatToggleCaret toggle={toggle} onClick={toggleChat} ref={ref}>
            <Popover id="popover-caret" content={contentOpen}>
                {!toggle && <MessageOutlined />}
            </Popover>
        </ChatToggleCaret>
    ));

    return (
        <>
            {/* <Controls playState={PlayState.stop}> */}
            <PulsingChatWrapper>
                <ChatTogglerComponent ref={toggleWrapperRef} />
            </PulsingChatWrapper>
            {/* </Controls> */}
            <ChatRoomComponent />
        </>
    );
};

export default Chat;
