import { Col, Row } from 'antd';
import { useLocalStorage } from 'beautiful-react-hooks';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { Well } from '../../../components/Channel/Channel.styled';
import {
    ContentBodyWrapper,
    ContentImgWrapper,
    ContentTitleWrapper,
    ContentWrapper,
} from '../../../components/Channel/Content.styled';
import { LazyImage } from '../../../components/Channel/Helpers/LazyLoadImg';
import { placeholderImg } from '../../../components/Channel/Helpers/placeholderImg';
import { isReallyEmpty, isReallyNotEmpty } from '../../../components/Channel/Helpers/ramdaHelpers';
import { ToDate } from '../../../components/Channel/Helpers/ToDate';
import { PageWrapper, SkeletonLoader, TheDivider } from '../../../Global.styled';
import { ChannelContentWrapper } from './ChannelPage.styled';
import useSubscriptions from './useSubscriptions';

interface Props {
    user: firebase.User | null;
}
const initSkeletonCount = 1;

const SubscriptionsPage: FC<Props> = ({ user }) => {
    const history = useHistory();
    const [skeletonCount, setSkeletonCount] = useLocalStorage('subscription-pg-skeletonCount', initSkeletonCount);
    const { subscriptions, getSubscriptions } = useSubscriptions();
    const currentAuthUser = user;

    useEffect(() => {
        if (currentAuthUser) {
            getSubscriptions(currentAuthUser!.uid);
        }
    }, [currentAuthUser]);

    useEffect(() => {
        if (isReallyNotEmpty(subscriptions) && subscriptions) {
            if (subscriptions.length > 1) {
                if (Number(skeletonCount) === subscriptions.length) {
                    return;
                } else {
                    setSkeletonCount(subscriptions.length);
                }
            } else {
                return;
            }
        }
    }, [subscriptions, skeletonCount]);

    const viewLiveContent = useCallback(
        (channelKey: string, key: string) => {
            history.push(`/explore/${channelKey}/content/${key}`);
        },
        [history],
    );

    return (
        <PageWrapper className="subscriptions-page">
            <ChannelContentWrapper>
                {isReallyEmpty(subscriptions) && <p>Currently no content 😢</p>}

                <Row justify="center" gutter={[16, 16]}>
                    {isReallyEmpty(subscriptions) ? (
                        <>
                            {/* creates and iterates an empty number of arrays */}
                            {[...Array(skeletonCount)].map((_, i) => (
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={6} key={i}>
                                    <SkeletonLoader loading={true} active avatar />
                                </Col>
                            ))}
                        </>
                    ) : (
                        <>
                            {isReallyNotEmpty(subscriptions) &&
                                subscriptions.map(
                                    ({
                                        id,
                                        key,
                                        channelKey,
                                        title,
                                        description,
                                        category,
                                        imgUrl,
                                        createdAt,
                                        currentUser,
                                        views,
                                    }) => {
                                        return (
                                            <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={6} key={id}>
                                                <ContentWrapper
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => viewLiveContent(channelKey, key)}
                                                >
                                                    <ContentImgWrapper>
                                                        <LazyImage
                                                            className="public"
                                                            src={imgUrl === '' ? placeholderImg : imgUrl}
                                                            alt={title}
                                                        />
                                                    </ContentImgWrapper>
                                                    <ContentTitleWrapper>
                                                        <img src={currentUser?.photoURL} className="userImg" />
                                                        <div className="title">{title}</div>
                                                    </ContentTitleWrapper>
                                                    <ContentBodyWrapper>
                                                        <div className="desc" title={description}>
                                                            {description}
                                                        </div>
                                                        <span className="more-info">
                                                            <div>
                                                                <span className="views">{views} views</span> {' - '}
                                                                <span className="createdAt">
                                                                    <Moment fromNow>{ToDate(createdAt)}</Moment>
                                                                </span>
                                                            </div>
                                                        </span>
                                                    </ContentBodyWrapper>
                                                </ContentWrapper>
                                            </Col>
                                        );
                                    },
                                )}
                        </>
                    )}
                </Row>
            </ChannelContentWrapper>
        </PageWrapper>
    );
};

export default SubscriptionsPage;
