// import { fbStorageTaskEventStateChanged, firebaseStorage } from '../../../firebase/firebaseConfig';
import { RespIframe } from './ResponsiveIframe';

const TinyApiKey = process.env.REACT_APP_tiny_api;

// const storageRef = firebaseStorage.ref();

const TinySettings = {
    skin: (window.matchMedia("(prefers-color-scheme: dark)").matches ? "oxide-dark" : ""),
    content_css: (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : ""),
    height: 800,
    menubar: true,
    branding: false,
    media_live_embeds: true,
    draggable_modal: true,
    toolbar_mode: 'scrolling',
    content_style: '.mce-object-iframe { display: block; } .mce-content-body .img-responsive { max-width: 100%; }',
    // content_css: "./TinyMCEContentEditor.css",
    image_class_list: [
        { title: 'Responsive Image', value: 'img-responsive' },
    ],
    mobile: {
        menubar: true,
        plugins: [
            'image imagetools anchor preview print',
            'code fullscreen',
            'insertdatetime media table paste wordcount code autosave',
        ],
        toolbar: [
            'insertfile undo redo | formatselect | fontselect | fontsizeselect styleselect | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
        ],
    },
    plugins: [
        'advlist autolink lists link tinydrive image imagetools charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount autosave',
    ],
    imagetools_cors_hosts: ['https://each1teach.one/', 'http://localhost:3000/'],
    toolbar:
        'insertfile undo redo | formatselect | fontselect | image | fontsizeselect styleselect | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
    image_list: [
        { title: 'Random water image', value: 'https://source.unsplash.com/weekly?water' },
        { title: 'Random technology img', value: 'https://source.unsplash.com/weekly?technology' },
    ],
    image_uploadtab: true,
    // file_picker_callback: (callback: any, value: any, meta: any) => {
    //     // console.log('file_picker_callback value', value);
    //     if (meta.filetype == 'image') {
    //         const upload = document.getElementById('imgUpload') as HTMLInputElement;
    //         const currentUID = upload.dataset.currentuid;

    //         upload?.click();

    //         const checkImgFile = () => {
    //             if (upload) {
    //                 const files = upload?.files;
    //                 // console.log("checkImgFile -> files", files)
    //                 let imgFile;
    //                 if (files) {
    //                     imgFile = files[0];
    //                     // todo check a way to show % uploaded
    //                     // todo list all files https://firebase.google.com/docs/storage/web/list-files
    //                     // const imgName = `content/${userId}/img-${new Date().getTime()}`;
    //                     const imgName = `contents/${currentUID}/img-${new Date().getTime()}-${imgFile?.name}`;
    //                     const imageRef = storageRef.child(imgName);
    //                     const task = imageRef.put(imgFile);

    //                     task.on(
    //                         fbStorageTaskEventStateChanged,
    //                         (snapshot) => {
    //                             const progress = Math.round((100 * snapshot.bytesTransferred) / snapshot.totalBytes);
    //                             console.log('Upload is ' + progress + '% done');
    //                         },
    //                         (error) => {
    //                             console.log('onChange -> error', error);
    //                         },
    //                         async () => {
    //                             await task.snapshot.ref
    //                                 .getDownloadURL()
    //                                 .then((downloadURL) => {
    //                                     console.log('downloadURL', downloadURL);
    //                                     callback(downloadURL, {
    //                                         alt: imgName,
    //                                     });
    //                                 })
    //                                 .catch((error) => {
    //                                     console.log('error', error);
    //                                 })
    //                                 .finally(() => {});
    //                         },
    //                     );
    //                 }
    //             }
    //         };
    //         upload?.addEventListener('change', checkImgFile);
    //     }
    // },
    setup: (editor: any) => {
        editor.on('change', () => {
            // todo: grabs the iframe id of tinymce and grabs iframe sub elements
            const tinyMceIframe = document.getElementById('tiny-wysiwyg_ifr');
            // @ts-ignore
            const iframes = tinyMceIframe.contentWindow.document.querySelectorAll('iframe');
            // const isResponsive = iframes
            if (iframes.length) {
                RespIframe(iframes, tinyMceIframe);
            }
            // todo - fixme / add a unsubscribe hook
        });
    },
    tinydrive_token_provider: (success: any, failure: any) => {
        const upload = document.getElementById('imgUpload') as HTMLInputElement;
        const currentUID = upload.dataset.currentuid;
        const currentUser = upload.dataset.currentuser;

        const url = 'https://us-central1-covid-gig-donations.cloudfunctions.net/tinyDrive/jwt';
        const data = {
            user: {
                displayName: currentUser,
                uid: currentUID,
            },
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                success({ token: data.token });
            })
            .catch((error) => {
                console.error('Error:', error);
                failure('Could not create a jwt token');
            });
    },
};

export { TinyApiKey, TinySettings };
