import styled, { css } from 'styled-components';
import { BoxShadow } from '../../Global.styled';
import { Input, Select } from 'antd';
const { Search } = Input;
const { Option } = Select;

interface ContentBodyProps {
    overflowDescEllipsis?: boolean;
}

// FIXME: Fix the ... for mobile
const lineHeight = 1.2;
const lineCount = 2;
const bgColor = 'transparent';
let maxHeight = lineHeight * lineCount;

const multiLineEllipsisHelper = css`
    overflow: hidden;
    position: relative;
    line-height: ${lineHeight}em;
    max-height: ${maxHeight}em;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: ${bgColor};
    }
`;

const multiLineDefaultHelper = css`
    position: relative;
    line-height: ${lineHeight}em;
    max-height: ${maxHeight}em;
    text-align: left;
    align-items: center;
    height: 100%;
`;

const Well = styled.div`
    flex: 1 1 0%;
    margin: 10px auto;
    max-width: 1400px;
    background: transparent;
`;

const ContentWrapper = styled(BoxShadow)`
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    background: transparent;
    @media only screen and (max-width: 600px) {
        &.public {
            height: 220px;
        }
        &.public.heightFit {
            height: 200px;
        }
    }
`;

const ContentImgWrapper = styled.div`
    img {
        object-fit: cover;
        min-height: 200px;
        max-height: 200px;
        border-radius: 2px;
        @media only screen and (max-width: 600px) {
            &.public {
                min-height: 120px;
                max-height: 120px;
            }
        }
    }
`;
const ContentTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    line-height: 2rem;
    padding: 0px 10px;

    > div {
        margin: 5px 5px;
    }
    &.public {
        .userImg {
            @media only screen and (max-width: 600px) {
                height: 40px;
            }
        }
    }
    .flexColumn {
        display: flex;
        flex-direction: column;

        /* white-space: nowrap;
        width: 100%; */
        overflow: hidden;
        /* text-overflow: ellipsis; */
    }
    .userImg {
        z-index: 1;
        margin-top: -20px;
        width: auto;
        height: 60px;
        border-radius: 100%;
        transition: 500ms;
        opacity: 1;
        position: relative;
        background-color: gold;
        -webkit-box-shadow: 7px 7px 25px -4px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 7px 7px 25px -4px rgba(0, 0, 0, 0.3);
        box-shadow: 7px 7px 25px -4px rgba(0, 0, 0, 0.3);
        &:hover {
            opacity: 0.8;
            transition: 500ms;
        }
    }
    /* styles for public viewing content  */
    .title {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .channelName {
        font-size: 12px;
        line-height: 15px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.public {
        @media only screen and (max-width: 600px) {
            min-height: 40px;
            .title {
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 20px;
                line-height: initial;
            }
        }
    }

    /* styles for auth content  */
    .wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .title {
            font-size: 1.2rem;
            white-space: nowrap;
            width: 100%;
            max-width: 85%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .status {
            top: 5%;
            right: 5%;
            padding: 0px 5px;
            font-size: 0.9rem;
            position: absolute;
            height: fit-content;

            &.draft {
                color: white;
                background: orange;
                border: 1px dotted orange;
            }
            &.public {
                color: white;
                background: green;
                border: 1px dotted green;
            }
            &.unlisted {
                color: black;
                background: rgb(249, 215, 36);
                border: 1px dotted rgb(249, 215, 36);
            }
        }
    }
`;
const ContentBodyWrapper = styled.div<ContentBodyProps>`
    display: block;
    font-size: 14px;
    padding: 0px 0px 10px 0px;

    &.public {
        @media only screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            padding: 0px;
            height: 100%;

            .desc {
                ${({ overflowDescEllipsis }) =>
                    overflowDescEllipsis ? multiLineEllipsisHelper : multiLineDefaultHelper};
                font-size: 12px;
                margin: 6px;
            }
        }
    }

    .desc {
        display: flex;
        margin: 0px 10px;
        font-size: 16px;
        @media only screen and (min-width: 600px) {
            display: block;
            white-space: pre-wrap;
            /* white-space: nowrap; */
            width: auto;
            /* overflow: hidden;
            text-overflow: ellipsis; */
        }
    }

    .more-info {
        display: flex;
        align-items: self-end;
        flex: 0 1;

        > div {
            margin: 0px 10px;
            @media only screen and (max-width: 600px) {
                &.public {
                    font-size: 10px;
                    margin: 0px 5px;
                    position: absolute;
                    bottom: 14px;
                }
                &.public.heightFit {
                    bottom: 35px;
                }
            }
        }

        .views {
            color: grey;
        }
        .createdAt {
            color: grey;
        }
    }
`;

const ContentOptionsWrapper = styled.div`
    display: flex;
    padding: 5px;
    margin-top: 5px;
    border-top: 1px solid whitesmoke;
    justify-content: space-between;
`;

const ContentSearchBar = styled(Search)`
    width: 500px;
    max-width: 100%;
`;

const ContentFilterSelect = styled(Select)`
    width: 100px;
    max-width: 100%;
`;
const ContentFilterOption = styled(Option)``;

type ContentSearchandFilterWrapperProps = {
    top?: number;
    bottom?: number;
};
const ContentSearchandFilterWrapper = styled.div<ContentSearchandFilterWrapperProps>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${({ top }) => (top ? top : 0)}%;
    margin-bottom: ${({ bottom }) => (bottom ? bottom : 0)}%;
`;

const ContentPrevCoverImg = styled.img`
    object-position: 50% 50%;
    object-fit: cover;
    height: 250px;
    /* object-fit: cover; */
    /* object-position: center; */
    -webkit-box-shadow: inset 0px -20px 60px -20px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: inset 0px -20px 60px -20px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0px -20px 60px -20px rgba(0, 0, 0, 0.4);
`;

const CopyToClipStyledWrapper = styled.div`
    top: 15%;
    right: 5%;
    padding: 0px 5px;
    font-size: 0.9rem;
    position: absolute;
`;

const ContentLaunchDate = styled.span`
    .ant-statistic {
        display: flex;
        margin-top: 5px;
    }
    .ant-statistic-title {
        font-size: 12px;
        margin-right: 5px;
    }
    .ant-statistic-content {
        font-size: 12px;
        border: 1px dotted grey;
        padding: 0 5px;
    }
`;

export {
    Well,
    ContentWrapper,
    ContentImgWrapper,
    ContentTitleWrapper,
    ContentBodyWrapper,
    ContentOptionsWrapper,
    ContentSearchandFilterWrapper,
    ContentSearchBar,
    ContentFilterSelect,
    ContentFilterOption,
    ContentPrevCoverImg,
    CopyToClipStyledWrapper,
    ContentLaunchDate,
};
