import { compose, curry, juxt, mergeAll, path, pick, tap, objOf } from 'ramda';
import { pubChanURL } from '../../components/Channel/Helpers/pubChanURL';

const getUserPhoto = compose(objOf('photoURL'), path(['currentUser', 'photoURL']));
const getChannelStuff = pick(['title', 'key']);
const addPublicUrl = (obj: any) => {
    return { ...obj, publicChannelUrl: `${pubChanURL}/${obj.key}` };
};
const getChannelInfo = compose(addPublicUrl, getChannelStuff);
/* @ts-ignore */
const getNotificationData = (val) => compose(mergeAll, juxt([getChannelInfo, getUserPhoto]))(val);

export { getNotificationData };
