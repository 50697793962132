import URLSearchParams from '@ungap/url-search-params';
import { isReallyEmpty } from '../../../../components/Channel/Helpers/ramdaHelpers';
// https://github.com/WebReflection/url-search-params

const getParam = (val: string, location: any) => {
  let params = new URLSearchParams(location.search);
  let res = params.getAll(val);
  if (isReallyEmpty(res)) {
    return '';
  }

  return res;
}

export { getParam }