import React, { FC, useState, useEffect, Suspense, lazy } from 'react';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface Props {
    id: string;
    icon?: any;
    props?: any;
    title?: string;
    type?: any;
    danger?: boolean;
    okText?: string;
    cancelText?: string;
    action: (id: string) => void;
}

const CRUDModal: FC<Props> = ({ action, id, title, icon, type, okText, cancelText, danger }) => {
    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = (e: any) => {
        // console.log(e);
        action(id);
        setVisible(false);
    };

    const handleCancel = (e: any) => {
        // console.log(e);
        setVisible(false);
    };

    return (
        <div>
            <Button type={type} icon={icon} onClick={showModal} danger={danger} />

            <Modal
                title={title}
                visible={visible}
                okText={okText}
                cancelText={cancelText}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>
                    <ExclamationCircleOutlined /> <span>Please note this action is permanent</span>
                </p>
            </Modal>
        </div>
    );
};

export default CRUDModal;
