import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useContent } from '../../context/Content/ContentContext';

import Moment from 'react-moment';
import { ToDate } from './Helpers/ToDate';
import { placeholderImg } from './Helpers/placeholderImg';
import { Content } from '../../interfaces/Channel';

import { EyeOutlined, CopyOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    Well,
    ContentWrapper,
    ContentLaunchDate,
    ContentTitleWrapper,
    ContentBodyWrapper,
    ContentOptionsWrapper,
    CopyToClipStyledWrapper,
} from './Content.styled';
import { Row, Col, message, Button, Tooltip, Statistic } from 'antd';
import { LazyImage } from './Helpers/LazyLoadImg';
import CRUDModal from './Helpers/CRUDModal';
import { isReallyNotEmpty } from './Helpers/ramdaHelpers';
import { CopyToClipboardComp } from './Helpers/CopyToClipboardComp';
import { pubChanURL } from './Helpers/pubChanURL';
const { Countdown } = Statistic;

interface Props {
    contentsOfChannel: Content[] | null | undefined;
    selectViewOption: string;
}

const ContentCards: FC<Props> = ({ selectViewOption, contentsOfChannel }) => {
    let history = useHistory();
    const { deleteContentById, updateContentPageCountDown } = useContent();

    const editContent = useCallback(
        (channelId: string, id: string) => {
            history.push(`/channel/${channelId}/content/edit/${id}`);
        },
        [history],
    );

    const copyContent = useCallback(
        (channelId: string, id: string) => {
            history.push(`/channel/${channelId}/content/copy/${id}`);
        },
        [history],
    );

    const deleteContent = useCallback(
        (id: string) => {
            deleteContentById(id).then(() => {
                // todo -> set a version key or archive option?
                // console.log('deleteContent', id);
                message.info('Content deleted.');
            });
        },
        [deleteContentById, message],
    );

    const viewLiveContent = useCallback(
        (channelKey: string, key: string) => {
            message.info('Viewing as live');
            history.push({ pathname: `/explore/${channelKey}/content/${key}`, state: { fromPath: 'auth-chan' } });
        },
        [history, message],
    );

    const viewDraftContent = useCallback(
        (channelId: string, key: string) => {
            message.info('Viewing as draft');
            history.push(`/channel/${channelId}/draft/${key}`);
        },
        [history, message],
    );

    const onCountdownFinish = (id: any) => {
        // console.log('onCountdownFinish called', id);
        updateContentPageCountDown(id).then(() => console.log('onCountdownFinish finished!'));
    };

    return (
        <Well>
            {contentsOfChannel?.length && isReallyNotEmpty(contentsOfChannel) ? (
                <Row justify="center" gutter={[20, 20]}>
                    {contentsOfChannel.map(
                        ({
                            id,
                            key,
                            title,
                            description,
                            category,
                            channelId,
                            imgUrl,
                            createdAt,
                            launchDate,
                            draft,
                            public: _public,
                            editorValue,
                            currentUser,
                            channelKey,
                            views,
                        }) => {
                            return (
                                <Col className="gutter-row" xs={24} sm={20} md={16} lg={12} xl={8} key={id}>
                                    <ContentWrapper>
                                        <LazyImage
                                            type="content"
                                            src={imgUrl === '' ? placeholderImg : imgUrl}
                                            alt={title}
                                        />

                                        <ContentTitleWrapper>
                                            <img src={currentUser?.photoURL} className="userImg" />
                                            <div className="wrapper">
                                                <div className="title">{title}</div>
                                                {draft ? (
                                                    <div className={`status draft`}>Draft</div>
                                                ) : (
                                                    <>
                                                        <div className={`status ${_public ? 'public' : 'unlisted'}`}>
                                                            {_public ? 'Public' : 'Unlisted'}
                                                        </div>
                                                        <CopyToClipStyledWrapper>
                                                            <CopyToClipboardComp
                                                                textToCopy={`${pubChanURL}/${channelKey}/content/${key}`}
                                                                tooltipText={`copy your public content url to clipboard.`}
                                                            />
                                                        </CopyToClipStyledWrapper>
                                                    </>
                                                )}
                                            </div>
                                        </ContentTitleWrapper>
                                        <ContentBodyWrapper>
                                            <div className="desc" title={description}>
                                                {description}
                                            </div>
                                            <span className="more-info">
                                                <div>
                                                    <span className="views">{views} views</span> {' - '}
                                                    <span className="createdAt">
                                                        {!draft && <b>LIVE</b>}{' '}
                                                        {createdAt && <Moment fromNow>{ToDate(createdAt)}</Moment>}
                                                    </span>
                                                    {isReallyNotEmpty(launchDate) && draft && (
                                                        <ContentLaunchDate className="launchDate">
                                                            <Countdown
                                                                title={'Going live in'}
                                                                value={launchDate}
                                                                format="D day / H[h] / m[m] / s[s]"
                                                                onFinish={() => onCountdownFinish(id)}
                                                            />
                                                        </ContentLaunchDate>
                                                    )}
                                                </div>
                                            </span>
                                        </ContentBodyWrapper>
                                        {selectViewOption === 'me' && (
                                            <ContentOptionsWrapper>
                                                {draft ? (
                                                    <Tooltip title="View Draft">
                                                        <Button
                                                            icon={<EyeOutlined />}
                                                            onClick={() => viewDraftContent(channelId, key)}
                                                            type="link"
                                                            target={``}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="View Live">
                                                        <Button
                                                            icon={<EyeOutlined />}
                                                            onClick={() => viewLiveContent(channelKey, key)}
                                                            type="link"
                                                            target={``}
                                                        />
                                                    </Tooltip>
                                                )}
                                                <Tooltip title="Edit">
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        onClick={() => editContent(channelId, id)}
                                                        type="link"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Copy content">
                                                    <Button
                                                        icon={<CopyOutlined />}
                                                        onClick={() => copyContent(channelId, id)}
                                                        type="link"
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <CRUDModal
                                                        id={id}
                                                        action={deleteContent}
                                                        icon={<DeleteOutlined />}
                                                        title="You want to delete?"
                                                        type="link"
                                                        danger={true}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    />
                                                </Tooltip>
                                            </ContentOptionsWrapper>
                                        )}
                                    </ContentWrapper>
                                </Col>
                            );
                        },
                    )}
                </Row>
            ) : (
                <p>You currently have no content, let's add some!</p>
            )}
        </Well>
    );
};

export default ContentCards;

{
    /* <Button
        icon={<DeleteOutlined />}
        onClick={() => deleteContent(id)}
        type="link"
        danger
    /> */
}
