import React, { FC } from 'react';
import { Spin } from 'antd';
import { SkeletonAvatarLoader } from '../../Global.styled';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
    fontSize?: number;
}

const GlobalSpinnerLoader: FC<Props> = ({ fontSize = 24 }) => {
    const antIcon = <LoadingOutlined style={{ fontSize }} spin />;
    return <Spin indicator={antIcon} />;
};

const LoadingAvatar = (
    <span className="submenu-title-wrapper">
        <SkeletonAvatarLoader active size={'default'} shape={'circle'} />
    </span>
);

export { GlobalSpinnerLoader, LoadingAvatar };
