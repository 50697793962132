import React, { FC } from 'react';
import {
    GlobalOutlined,
    InstagramOutlined,
    FacebookOutlined,
    TwitterOutlined,
    GithubOutlined,
    GitlabOutlined,
    YoutubeOutlined,
    MediumOutlined,
    WechatOutlined,
    RedditOutlined,
    BehanceOutlined,
} from '@ant-design/icons';
import { SocialLinks } from '../../interfaces';
import { Button } from 'antd';
import { includes } from 'ramda';

interface Props {
    socialLinks?: SocialLinks[];
}

const socialIcons = {
    web: <GlobalOutlined />,
    facebook: <FacebookOutlined />,
    instagram: <InstagramOutlined />,
    twitter: <TwitterOutlined />,
    github: <GithubOutlined />,
    gitlab: <GitlabOutlined />,
    youtube: <YoutubeOutlined />,
    medium: <MediumOutlined />,
    wechat: <WechatOutlined />,
    reddit: <RedditOutlined />,
    behance: <BehanceOutlined />,
};
const SocialMedia: FC<Props> = ({ socialLinks }) => {
    const view = (link: any, icon: any) => {
        return (
            <Button icon={icon} type="link" href={`${link.link}`} target="_blank">
                {link.label ? link.label : ''}
            </Button>
        );
    };

    const social = (link: any) => {
        if (includes('facebook', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.facebook)}
                </div>
            );
        }
        if (includes('instagram', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.instagram)}
                </div>
            );
        }
        if (includes('twitter', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.twitter)}
                </div>
            );
        }
        if (includes('github', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.github)}
                </div>
            );
        }
        if (includes('gitlab', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.gitlab)}
                </div>
            );
        }
        if (includes('youtube', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.youtube)}
                </div>
            );
        }
        if (includes('medium', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.medium)}
                </div>
            );
        }
        if (includes('reddit', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.reddit)}
                </div>
            );
        }
        if (includes('wechat', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.wechat)}
                </div>
            );
        }
        if (includes('behance', link.link)) {
            return (
                <div key={link.id} className="link">
                    {view(link, socialIcons.behance)}
                </div>
            );
        }
        return (
            <div key={link.id} className="link">
                {view(link, socialIcons.web)}
            </div>
        );
    };
    return <>{socialLinks?.map(social)}</>;
};

export { SocialMedia };
