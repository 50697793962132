import styled from 'styled-components';

type ToggleProp = {
    toggle?: boolean | null;
};

const chatTransYHeight = 475;
const ChatRoom = styled.div<ToggleProp>`
    position: fixed;
    right: 15px;
    bottom: 15px;
    background: #011529;
    padding: 10px;
    z-index: 2;
    max-width: 350px;
    transform: ${({ toggle }) => (toggle ? 'translateY(-10px)' : `translateY(${chatTransYHeight}px)`)};
    /* transition: 500ms ease-in-out; */
    opacity: ${({ toggle }) => (toggle ? '1' : '0')};
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
`;

const bottomVal = 0;
const ChatToggleCaret = styled.div<ToggleProp>`
    position: fixed;
    right: 60px;
    bottom: ${({ toggle }) => (toggle ? `${bottomVal - 50}px` : `${bottomVal}px`)};
    background: transparent;
    padding: 10px;
    z-index: 3;
    font-size: 20px;
    color: white;
    line-height: initial;
    cursor: pointer;
    border-radius: 100px;
    /* -webkit-border-top-left-radius: 100px;
    -webkit-border-top-right-radius: 100px;
    -moz-border-radius-topleft: 100px;
    -moz-border-radius-topright: 100px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px; */
`;

export { ChatRoom, ChatToggleCaret };
