import React from 'react';
import ReactDOM from 'react-dom';

import { UserProvider } from './context/User/UserContext';
import { ChannelProvider } from './context/Channel/ChannelContext';
import { ContentProvider } from './context/Content/ContentContext';
import { GlobalStyle } from './Global.styled';

import App from './App';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <React.StrictMode>
        <UserProvider>
            <ChannelProvider>
                <ContentProvider>
                    <GlobalStyle />
                    <App />
                </ContentProvider>
            </ChannelProvider>
        </UserProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
