import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const settings = {
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'cn', 'es'],
    backend: {
        /* translation file path */
        loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['translations'],
    defaultNS: 'translations',
    // keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },
};

i18n.use(LanguageDetector).use(HttpApi).use(initReactI18next).init(settings);

export default i18n;
