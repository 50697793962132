import { User } from './../../../interfaces/User';
import { v4 as uuidv4 } from 'uuid';

import { timestamp } from './../../../firebase/firebaseHelpers';
import { Channel } from './../../../interfaces/Channel';

const ChannelDataHelper = (user: User | null) => {
    const defaultKey = user?.name.split(' ').join('-') + '-' + uuidv4().substr(0, 5);
    const initialChannelData: Channel = {
        id: uuidv4(),
        key: defaultKey,
        title: '',
        description: '',
        coverImgUrl: '',
        createdAt: timestamp,
        coverImgPositionY: 50,
        userId: user?.id,
        currentUser: user,
        views: 1,
        donate: { type: 'none', handle: '', img: '' },
    }

    return initialChannelData;
};

export { ChannelDataHelper }