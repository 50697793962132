import React, { FC, Suspense, lazy, useEffect, useState } from 'react';
import { WrappedComponentProps } from 'react-with-firebase-auth';
import { KofiPixelLogo } from '../../imgs';

import NavMenu from '../../components/NavigationBar';
import AuthUserChannel from '../../components/Channel/AuthUserChannel';
// import PublicUserChannel from '../../components/Channel/PublicUserChannel';
// import { useUsers } from '../../context/User/UserContext';
import AuthHOC from '../../firebase/firebaseAuth';
import { Switch, Route, useLocation, Redirect, NavLink } from 'react-router-dom';

import ContentForm from '../../components/Channel/ContentForm';
// import { replace } from 'ramda';
import { TheFooter, SkeletonLoader } from '../../Global.styled';
// import ZoomChannel from '../../components/Zoom/Zoom';
import {
    LayoutStyled,
    HeaderStyled,
    ContentStyled,
    BreadcrumbsStyled,
    SiteLayoutBackground,
    Layout100HeightStyled,
    BreadCrumbAndLangStyled,
} from './MainDashboard.styled';

import { useTranslation } from 'react-i18next';

import MainDashboardSideMenu from './MainDashboardSideMenu';
import { Select } from 'antd';
import WelcomeModalFirstTimer from './Public/WelcomeModalFirstTimer';
import { getParam } from './Public/helpers/urlSearchParams';
import { isReallyNotEmpty } from '../../components/Channel/Helpers/ramdaHelpers';
import FourZeroFour from './Public/FourZeroFour';
import SubscriptionsPage from './Public/SubscriptionsPage';
import Chat from '../../components/Chat';
import ContentFormPub from './Public/ContentForm';

const PublicUserChannel = lazy(() => import('../../components/Channel/PublicUserChannel'));
const MarketingComponent = lazy(() => import('./Public/MarketingComponent'));
const UserSettings = lazy(() => import('../../components/User/UserSettings'));
const TheMission = lazy(() => import('./Public/TheMission'));
const ChannelPage = lazy(() => import('./Public/ChannelPage'));
const ContentPage = lazy(() => import('./Public/ContentPage'));

const TermsOfService = lazy(() => import('./Public/TermsOfService'));
const PrivatePolicy = lazy(() => import('./Public/PrivatePolicy'));
const AComponent = lazy(() => import('../../components/Test/AComponent'));
const Todos = lazy(() => import('../../components/Todos/Todos'));

const { Option } = Select;
// import useScript('') from '../../components/Channel/Helpers/useScript';
// import { ZoomTest } from '../../components/Channel/ZoomTest';
// const ZoomTest = lazy(() => import('../../components/Channel/ZoomTest'));
// const ZoomVideo = lazy(() => import('../../components/Channel/ZoomVideo'));
// import { User } from '../../interfaces';

interface Props extends WrappedComponentProps {}

const MainDashboard: FC<Props> = ({ user }) => {
    const location = useLocation();
    const [paramVal] = getParam('lng', location);

    const { t, i18n } = useTranslation(['translation', 'dashboard']);
    const handleLangChange = (lang: string) => i18n.changeLanguage(lang);

    useEffect(() => {
        if (isReallyNotEmpty(paramVal)) {
            setTimeout(() => handleLangChange(paramVal), 1000);
        }
    }, []);

    const BreadcrumbsLink = () => {
        const breadcrumbs = location.pathname.split('/');
        return breadcrumbs.map((crumb: string, index: number) => {
            let crumbs;
            if (index === 1) {
                crumbs = (
                    <NavLink to={`/${crumb}`}>
                        <span>{crumb}</span>
                    </NavLink>
                );
            } else if (index === 2) {
                // console.log('BreadcrumbsLink -> crumb', crumb);
                crumbs = (
                    <NavLink to={`/${breadcrumbs[1]}/${crumb}`}>
                        <span>{crumb}</span>
                    </NavLink>
                );
            } else {
                crumbs = crumb;
            }
            return <BreadcrumbsStyled.Item key={crumb}>{crumbs}</BreadcrumbsStyled.Item>;
        });
    };

    return (
        <>
            {/* todo: set first time modal OK to cache localstorage */}
            <WelcomeModalFirstTimer />
            <Layout100HeightStyled>
                <MainDashboardSideMenu user={user} location={location} />

                <LayoutStyled hasSider={false} className="site-layout">
                    {/* Main navbar */}
                    <HeaderStyled prefixCls="ant-layout-header mobile">
                        <NavMenu />
                    </HeaderStyled>

                    <ContentStyled className="menu-overlay">
                        <BreadCrumbAndLangStyled>
                            <BreadcrumbsStyled separator=">">
                                {BreadcrumbsLink()}
                                {/* language selector */}
                            </BreadcrumbsStyled>
                            <Select
                                defaultValue={paramVal || t('translation:en')}
                                style={{ width: 'auto' }}
                                onChange={handleLangChange}
                            >
                                <Option value={`en`} name="language">
                                    {t('translation:en')}
                                </Option>
                                <Option value={`es`} name="language">
                                    {t('translation:es')}
                                </Option>
                                <Option value={`cn`} name="language">
                                    {t('translation:cn')}
                                </Option>
                            </Select>
                        </BreadCrumbAndLangStyled>
                        <SiteLayoutBackground>
                            {/* <Suspense fallback={<div>Loading...</div>}> */}
                            <Switch>
                                <Route path="/" exact>
                                    <Redirect to="/explore" />
                                </Route>

                                <Route
                                    name="All Content"
                                    path="/explore"
                                    component={() => (
                                        <Suspense fallback={<SkeletonLoader active />}>
                                            <PublicUserChannel />
                                        </Suspense>
                                    )}
                                    exact
                                />

                                <Route
                                    name="marketing content"
                                    path="/marketing"
                                    component={() => (
                                        <Suspense fallback={<SkeletonLoader active />}>
                                            <MarketingComponent />
                                        </Suspense>
                                    )}
                                    exact
                                />

                                <Route
                                    name="Feature todos"
                                    path="/todos"
                                    component={() => (
                                        <Suspense fallback={<SkeletonLoader active />}>
                                            <Todos />
                                        </Suspense>
                                    )}
                                    exact
                                />

                                <Route
                                    name="Public channel view"
                                    path="/explore/:channelKey"
                                    component={() => (
                                        <Suspense fallback={<SkeletonLoader active />}>
                                            <ChannelPage />
                                        </Suspense>
                                    )}
                                    exact
                                />

                                {/* <Route
name="Public channel view from channelkey"
path="/explore/:channelKey"
component={() => (
		<Suspense fallback={<SkeletonLoader active />}>
				<ChannelPage />
		</Suspense>
)}
exact
/> */}

                                <Route
                                    name="Public content view"
                                    path="/explore/:channelId/content/:contentKey"
                                    component={() => (
                                        <Suspense fallback={<SkeletonLoader active />}>
                                            <ContentPage />
                                        </Suspense>
                                    )}
                                    exact
                                />

                                <Route
                                    name="Draft content view"
                                    path="/channel/:channelId/draft/:contentKey"
                                    component={() => (
                                        <Suspense fallback={<SkeletonLoader active />}>
                                            <ContentPage />
                                        </Suspense>
                                    )}
                                    exact
                                />

                                {user && (
                                    <Route
                                        name="CRUD Content"
                                        path="/channel/:channelId/content/:action/:id?"
                                        component={ContentForm}
                                        exact
                                    />
                                )}

                                {/* <Route name="Zoom Channel" path="/zoom" component={ZoomChannel} exact /> */}

                                {user && (
                                    <Route
                                        name="User settings"
                                        path="/user-settings"
                                        component={() => (
                                            <Suspense fallback={<SkeletonLoader active />}>
                                                <UserSettings user={user} />
                                            </Suspense>
                                        )}
                                        exact
                                    />
                                )}

                                <Route
                                    name="App mission statement"
                                    path="/mission"
                                    component={() => (
                                        <Suspense fallback={<div>Loading The Mission ...</div>}>
                                            <TheMission />
                                        </Suspense>
                                    )}
                                    exact
                                />

                                <Route
                                    name="App terms"
                                    path="/terms"
                                    component={() => (
                                        <Suspense fallback={<div>Loading the terms ...</div>}>
                                            <TermsOfService />
                                        </Suspense>
                                    )}
                                    exact
                                />

                                <Route
                                    name="App privacy"
                                    path="/privacy"
                                    component={() => (
                                        <Suspense fallback={<div>Loading the privacy ...</div>}>
                                            <PrivatePolicy />
                                        </Suspense>
                                    )}
                                    exact
                                />

                                {/* <Route
name="App legal"
path="/legal"
component={() => (
<Suspense fallback={<div>Loading the legal ...</div>}>
		<TheMission />
</Suspense>
)}
exact
/> */}

                                {!user && (
                                    <Route
                                        name="Public content wysiwyg"
                                        path="/content"
                                        component={() => <ContentFormPub />}
                                        exact
                                    />
                                )}
                                {user && (
                                    <Route
                                        name="Auth User Content"
                                        path="/channel"
                                        component={() => <AuthUserChannel user={user} />}
                                        exact
                                    />
                                )}

                                {user && (
                                    <Route
                                        name="Auth User Subscriptions"
                                        path="/subscriptions"
                                        component={() => <SubscriptionsPage user={user} />}
                                        exact
                                    />
                                )}

                                <Route
                                    name="test component"
                                    path="/test-my-shit"
                                    component={() => <AComponent />}
                                    exact
                                />

                                <Route name="404 page" component={FourZeroFour} />
                            </Switch>
                            {/* </Suspense> */}
                        </SiteLayoutBackground>
                    </ContentStyled>
                    <Chat />
                    <TheFooter>
                        <span>
                            {t('dashboard:footer.text')}{' '}
                            <a href="https://supwill.dev" target="_blank">
                                ⚠Will⚠
                            </a>{' '}
                            -{' '}
                        </span>
                        <a href="https://ko-fi.com/iamwill" target="_blank">
                            <img className="kofi-logo" src={KofiPixelLogo} alt="Donate Kofi logo" />{' '}
                            <span>{t('dashboard:donate.kofiText')}</span>
                        </a>{' '}
                        <div>©2020</div>
                        <div>
                            <NavLink to={`/mission`}>
                                <span>{t('dashboard:legal.mission')}</span>
                            </NavLink>
                            {' | '}
                            <NavLink to={`/terms`}>
                                <span>{t('dashboard:legal.terms')}</span>
                            </NavLink>
                            {' | '}
                            <NavLink to={`/privacy`}>
                                <span>{t('dashboard:legal.privacy')}</span>
                            </NavLink>
                        </div>
                    </TheFooter>
                </LayoutStyled>
            </Layout100HeightStyled>
        </>
    );
};

export default AuthHOC(MainDashboard);

//     {/* <Route
//     name="Public User Channel"
//     path="/channel/:channelId"
//     component={PublicUserChannel}
// /> */}
