import React, { FC, useState, useEffect } from 'react';
import './WYSIWYG.scss';
import { Formik } from 'formik';
import { Editor } from '@tinymce/tinymce-react';
import FormSteps from './Steps';
import { Content } from '../../../interfaces';
import { categories } from '../Helpers/Catergories';
import { DatePicker, SubmitButton, Input, Select, Checkbox, ResetButton, Form, FormItem } from 'formik-antd';
import { message, Button, Row, Col, Divider, Tooltip, Space } from 'antd';
import { RollbackOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useLocalStorage } from 'beautiful-react-hooks';
import { useUsers } from '../../../context/User/UserContext';
import { useChannels } from '../../../context/Channel/ChannelContext';
import { useContent } from '../../../context/Content/ContentContext';
import { Well, EditorWell, CenterImgAndMaxWidth100 } from '../Channel.styled';
import { addCopyPreText } from '../Helpers/addCopyPreText';
import ImageUploader from '../Helpers/ImageUploader';
import { TheDivider, SkeletonLoader } from '../../../Global.styled';
import { TinyApiKey, TinySettings } from '../Helpers/TinyMCE';
import { slugifyTitle } from '../../../utils/slugifyTitle';
import { convertToFireTimestamp, timestamp } from '../../../firebase/firebaseHelpers';
import { isReallyEmpty, isReallyNotEmpty } from '../Helpers/ramdaHelpers';
import { emailHelper } from '../../Email/Email';
import { pubChanURL } from '../Helpers/pubChanURL';
import { pluck } from 'ramda';
const { Option } = Select;

interface Props {
    action?: string;
    content: Content;
}

const validateRequired = (value: string) => {
    return value ? undefined : 'required';
};

type Params = {
    channelId?: string;
    id?: string;
};

const WysiwygForm: FC<Props> = ({ action, content }) => {
    const { channelId, id: contentId } = useParams<Params>();
    // console.log('WysiwygForm content', content);
    let history = useHistory();

    const {
        state: { currentUser: currentAuthUser },
    } = useUsers();

    const {
        getChannelById,
        state: { currentChannel },
    } = useChannels();

    const {
        addContent,
        editContentById,
        getContentById,
        doesKeyExistInMyContent,
        addNewTaskToSch,
        state: { currentContent },
    } = useContent();

    const [wysiwygEditorLocalSave, setWysiwygEditorLocalSave] = useLocalStorage(
        'wysiwyg-editor',
        content?.editorValue || currentContent?.editorValue || '',
    );
    const [contentData, setContentData] = useState<Content>(content || currentContent || null);
    const [editorData, setEditorData] = useState(content?.editorValue || currentContent?.editorValue || '');
    const [isDraft, setIsDraft] = useState(content?.draft || currentContent?.draft || false);
    const [loadingPublish, setLoadingPublish] = useState(false);
    const [loadingDraft, setLoadingDraft] = useState(false);
    const [uploadCoverImg, setUploadCoverImg] = useState(content?.uploadImg || currentContent?.uploadImg || false);
    const [coverImg, setCoverImg] = useState<string>(content?.imgUrl || currentContent?.imgUrl || '');
    const [validateAsyncKeyErrors, setValidateAsyncKeyErrors] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [tinyMceLoading, setTinyMceLoading] = useState(true);
    const [expiresAtSeconds, setExpiresAtSeconds] = useState(0);
    const [expiresAtMillisSeconds, setExpiresAtMilliseconds] = useState(0);
    // const [checkForIframe, setCheckForIframe] = useState(false);

    useEffect(() => {
        // TODO: get currentchannel data when someone refreshes
        if (currentChannel?.id) {
            return;
        } else {
            const getChannel = async () => {
                try {
                    await getChannelById(channelId).then(() => {
                        // console.log('get chan data due to refresh');
                    });
                } catch (error) {
                    console.log('getChannel -> error', error);
                }
            };
            getChannel();
        }
    }, [currentAuthUser, getChannelById, currentChannel, channelId]);

    useEffect(() => {
        // console.log('currentContent', currentContent);
        let incomingContent;
        const typeOfIncomingContent = (content: any) => {
            if (action === 'copy') {
                incomingContent = {
                    ...content,
                    key: addCopyPreText(content.key),
                    title: addCopyPreText(content.title),
                };
            } else {
                incomingContent = content;
            }
            setContentData(incomingContent);
        };

        // TODO: Check if contents is coming from props or else call api
        if (isReallyEmpty(content)) {
            // TODO: request it
            const getCurrentContent = async () => {
                if (currentContent) {
                    typeOfIncomingContent(currentContent);
                    return;
                }
                try {
                    await getContentById(contentId);
                } catch (error) {
                    console.log('getCurrentContent -> error', error);
                }
            };
            getCurrentContent();
        } else {
            typeOfIncomingContent(content);
        }
    }, [action, content, currentContent]);

    useEffect(() => {
        if (currentContent?.editorValue) {
            setEditorData(currentContent?.editorValue);
        }
        if (currentContent?.uploadImg) {
            setUploadCoverImg(currentContent?.uploadImg);
        }
        if (currentContent?.imgUrl) {
            setCoverImg(currentContent?.imgUrl);
        }
    }, [currentContent?.uploadImg, currentContent?.imgUrl, currentContent?.editorValue]);

    useEffect(() => {
        if (content?.editorValue) {
            setEditorData(content?.editorValue);
        }
        if (content?.uploadImg) {
            setUploadCoverImg(content?.uploadImg);
        }
        if (content?.imgUrl) {
            setCoverImg(content?.imgUrl);
        }
    }, [content?.uploadImg, content?.imgUrl, content?.editorValue]);

    useEffect(() => {
        if (uploadCoverImg === false) {
            setCoverImg('');
        }
    }, [uploadCoverImg]);

    useEffect(() => {
        // TODO: figure out the same for edit and copy
        // if (action === 'add') {
        setEditorData(wysiwygEditorLocalSave);
        // }
    }, []);

    const enterLoading = (submitType: string) => {
        if (submitType === 'draft') {
            setLoadingDraft(true);
            setIsDraft(true);
        } else {
            setLoadingPublish(true);
            setIsDraft(false);
        }
    };

    const schLaunchTask = (_contentId: any, values: any) => {
        let sourceURL = `${pubChanURL}/${currentChannel?.key}/content/${slugifyTitle(values.key)}`;
        let emailMailerHTML = {
            title: values.title,
            bodyTitle: values.title,
            bodyText: values.description,
            sourceURL: sourceURL,
            img: { cover: isReallyEmpty(coverImg) ? values.imgUrl : coverImg },
        };
        const markup = emailHelper(emailMailerHTML);

        const expTS = convertToFireTimestamp(expiresAtMillisSeconds);
        // create new task for scheduler

        let subersEmails;
        if (currentChannel?.subscribers) {
            subersEmails = pluck('email')(currentChannel?.subscribers);
        } else {
            subersEmails = [''];
        }

        const data = {
            id: _contentId,
            contentId: _contentId,
            launchDate: expTS,
            emails: [currentAuthUser?.email, ...subersEmails],
            displayName: currentChannel?.key,
            htmlMarkup: markup,
        };

        addNewTaskToSch(data)
            .then(() => {
                setTimeout(() => goBack(), 0);
            })
            .catch((err) => {
                if (err) {
                    message.error('Oops try scheduling ⏲ again.');
                }
            });
    };
    // add the disabled time here https://ant.design/components/date-picker/#header
    const saveToDb = (values: Content) => {
        let isPublic;
        let isLaunchDate = isReallyEmpty(values.launchDate) ? false : values.launchDate;
        if (isDraft) {
            isPublic = false;
        } else {
            isPublic = values.public;
        }
        const expTS = convertToFireTimestamp(expiresAtMillisSeconds);
        // console.log('saveToDb -> action', action);
        const formValues = {
            ...contentData, // this order matters
            ...values,
            draft: isDraft || Boolean(isLaunchDate),
            createdAt: isLaunchDate ? expTS : timestamp,
            launchDate: isLaunchDate,
            editorValue: editorData,
            currentUser: currentAuthUser,
            channelId: currentChannel?.id,
            key: slugifyTitle(values.key),
            channelKey: currentChannel?.key,
            public: isPublic,
            imgUrl: isReallyEmpty(coverImg) ? values.imgUrl : coverImg,
        };

        if (expiresAtSeconds > 0) {
            formValues.expiresAt = expiresAtSeconds;
        }

        console.log('saveToDb -> formValues', formValues);

        setWysiwygEditorLocalSave(''); // reset local storage

        if (action === 'add' || action === 'copy') {
            addContent(formValues)
                .then((content) => {
                    message.info('Content form saved!');
                    // if (isLaunchDate) {
                    schLaunchTask(content?.id, values);
                    // } else {
                    //     setTimeout(() => goBack(), 0);
                    // }
                })
                .catch((error) => {
                    console.log(`saveToDb ${action} -> error`, error);
                })
                .finally(() => {
                    setLoadingPublish(false);
                    setLoadingDraft(false);
                });
        } else if (action === 'edit') {
            editContentById(formValues)
                .then(() => {
                    message.info('Content form updated!');
                    // if (isLaunchDate) {
                    schLaunchTask(contentId, values);
                    // } else {
                    //     setTimeout(() => goBack(), 0);
                    // }
                })
                .catch((error) => {
                    console.log('saveToDb edit -> error', error);
                })
                .finally(() => {
                    setLoadingPublish(false);
                    setLoadingDraft(false);
                });
        } else {
            return;
        }
    };

    const handleEditorChange = (content: any, editor: any) => {
        // console.log('handleEditorChange -> editor', editor);
        setEditorData(content);
        setWysiwygEditorLocalSave(content);
    };

    const addCoverImg = (url: string) => {
        // console.log('addCoverImg -> url', url);
        setCoverImg(url);
    };

    const goBack = () => {
        history.push(`/channel`);
    };

    const validateAsyncKey = async (event: { target: { value: any } }) => {
        let key = slugifyTitle(event.target.value);
        if (isReallyNotEmpty(key)) {
            if (currentChannel?.id) {
                // TODO checks all content in my channel
                let exists = await doesKeyExistInMyContent(key, currentChannel?.id);
                // console.log('validateAsyncKey -> exists', exists);

                try {
                    if (exists && action !== 'edit') {
                        setIsValid(false);
                        setValidateAsyncKeyErrors('This name is taken, please try a different one.');
                    } else {
                        setIsValid(true);
                        setValidateAsyncKeyErrors('');
                    }
                } catch (error) {
                    console.log('onValidate -> error', error);
                }
            }
        } else {
            setValidateAsyncKeyErrors('');
        }
    };

    const onValidate = (values: any) => {
        const { key, title, description } = values;

        if (isReallyEmpty(key)) {
            setIsValid(false);
            return {
                key: 'required',
            };
        }
        if (validateAsyncKeyErrors) {
            setIsValid(false);
            return {
                key: 'requires update',
            };
        }
        if (isReallyEmpty(title)) {
            setIsValid(false);
            return {
                title: 'required',
            };
        }
        if (isReallyEmpty(description)) {
            setIsValid(false);
            return {
                description: 'required',
            };
        }
        setIsValid(true);
        return {};
    };

    const onEditorInit = () => {
        setTinyMceLoading(false);
    };

    const onDatePickerOk = (val: any) => {
        if (val) {
            const expiresAtInMilliSecs = val.valueOf();
            const expiresAtInSecs = val.diff(new Date(), 's');
            setExpiresAtSeconds(expiresAtInSecs);
            setExpiresAtMilliseconds(expiresAtInMilliSecs);
            // console.log('onDatePickerOk -> val', val.diff(new Date(), 's'));
        } else {
            setExpiresAtSeconds(0);
            setExpiresAtMilliseconds(0);
        }
    };

    return (
        <>
            <Row justify="center" style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Formik
                        initialValues={contentData}
                        onSubmit={(values, actions) => {
                            message.info('Saving form...');
                            saveToDb(values);
                            actions.setSubmitting(false);
                            // message.info(JSON.stringify(values, null, 4));
                            // actions.resetForm();
                        }}
                        validate={onValidate}
                        enableReinitialize
                        validateOnMount
                    >
                        {(formik) => {
                            const { values } = formik;
                            return (
                                <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }}>
                                    <FormSteps
                                        isValid={isValid}
                                        first={
                                            <Well>
                                                <FormItem
                                                    name="key"
                                                    label="key"
                                                    required={true}
                                                    validate={validateRequired}
                                                    className={
                                                        validateAsyncKeyErrors
                                                            ? ' ant-form-item-with-help ant-form-item-has-error'
                                                            : ''
                                                    }
                                                >
                                                    <Input
                                                        name="key"
                                                        onBlur={validateAsyncKey}
                                                        placeholder="a unique key (i.e. title, or something describing your content that's not used already)"
                                                        disabled={action === 'edit'}
                                                    />
                                                    <small>
                                                        .../channel/{currentChannel?.key}/content/
                                                        {values.key ? (
                                                            <strong style={{ color: '#40a9ff' }}>
                                                                {slugifyTitle(values.key)}
                                                            </strong>
                                                        ) : (
                                                            'your-key'
                                                        )}{' '}
                                                        <Tooltip
                                                            placement="top"
                                                            title={`A key is bascially a unique name that goes at the end of your URL, i.e. ${
                                                                values.key
                                                                    ? `/${slugifyTitle(values.key)}`
                                                                    : '/your-key'
                                                            }`}
                                                        >
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    </small>
                                                    {validateAsyncKeyErrors ? (
                                                        <div className="ant-form-item-explain">
                                                            {validateAsyncKeyErrors}
                                                        </div>
                                                    ) : null}
                                                </FormItem>
                                                <FormItem
                                                    name="title"
                                                    label="title"
                                                    required={true}
                                                    validate={validateRequired}
                                                >
                                                    <Input
                                                        name="title"
                                                        placeholder="title (i.e. A tutorial on Airflares)"
                                                    />
                                                </FormItem>
                                                <FormItem
                                                    name="description"
                                                    label="description"
                                                    required={true}
                                                    validate={validateRequired}
                                                >
                                                    <Input
                                                        name="description"
                                                        placeholder="description (i.e. describe the content, it will help the SEO)"
                                                    />
                                                </FormItem>
                                                <FormItem name="imgUrl" label="Cover photo">
                                                    {uploadCoverImg ? (
                                                        <Input
                                                            name="imgUrl"
                                                            placeholder="Uploading a custom image"
                                                            value={coverImg}
                                                        />
                                                    ) : (
                                                        <Input name="imgUrl" placeholder="optional add an image url" />
                                                    )}
                                                </FormItem>

                                                {!uploadCoverImg && values.imgUrl && (
                                                    <CenterImgAndMaxWidth100>
                                                        <TheDivider />
                                                        <p>Your current image preview</p>
                                                        <img src={values.imgUrl} />
                                                    </CenterImgAndMaxWidth100>
                                                )}
                                                <br />
                                                <Checkbox
                                                    name="uploadImg"
                                                    onChange={() => setUploadCoverImg(!uploadCoverImg)}
                                                >
                                                    {uploadCoverImg ? 'Coolz' : 'Upload a custom cover image?'}
                                                </Checkbox>
                                                {uploadCoverImg && (
                                                    <ImageUploader
                                                        userId={currentAuthUser?.id}
                                                        type="contents"
                                                        addCoverImg={addCoverImg}
                                                        coverImg={coverImg || values.imgUrl}
                                                    />
                                                )}
                                            </Well>
                                        }
                                        second={
                                            <EditorWell>
                                                {tinyMceLoading && <SkeletonLoader paragraph={true} />}

                                                <Editor
                                                    id="tiny-wysiwyg"
                                                    apiKey={TinyApiKey}
                                                    init={TinySettings}
                                                    onInit={onEditorInit}
                                                    // onSetContent={onSetContent}
                                                    // onGetContent={onGetContent}
                                                    onEditorChange={handleEditorChange}
                                                    initialValue={editorData || contentData.editorValue}
                                                />
                                                <input
                                                    name="image"
                                                    type="file"
                                                    id="imgUpload"
                                                    className="hidden"
                                                    data-currentuser={currentAuthUser?.name}
                                                    data-currentuid={currentAuthUser?.id}
                                                    style={{ display: 'none' }}
                                                ></input>
                                            </EditorWell>
                                        }
                                        last={
                                            <Well>
                                                <FormItem name="public" wrapperCol={{ offset: 0, xs: 20 }}>
                                                    <Checkbox name="public">
                                                        {values.public ? 'public' : 'make public or keep unlisted'}
                                                        <span style={{ margin: '2px' }}></span>
                                                        <Tooltip
                                                            placement="top"
                                                            title={
                                                                '"Public" is visible to everyone, "Unlisted" is only visible with a URL link'
                                                            }
                                                        >
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    </Checkbox>
                                                </FormItem>
                                                <Select
                                                    name={`category`}
                                                    defaultValue="Select a category"
                                                    style={{ width: 250 }}
                                                >
                                                    {categories.map((cat, i) => {
                                                        return (
                                                            <Option key={i} value={cat}>
                                                                {cat}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                <br />
                                                <br />
                                                <Space direction="vertical" size={12}>
                                                    <DatePicker
                                                        name="launchDate"
                                                        showToday
                                                        showTime
                                                        placeholder="Set a launch date"
                                                        onOk={onDatePickerOk}
                                                    />
                                                    {/* <DatePicker
                                                        showTime
                                                        onChange={onDatePickerChange}
                                                        onOk={onDatePickerOk}
                                                    /> */}
                                                </Space>
                                            </Well>
                                        }
                                        saveAsDraftBtn={
                                            <SubmitButton
                                                type="dashed"
                                                loading={loadingDraft}
                                                onClick={() => enterLoading('draft')}
                                                disabled={!isValid}
                                            >
                                                {loadingDraft ? 'Saving...' : 'Save as draft'}
                                            </SubmitButton>
                                        }
                                        quickSave={
                                            action !== 'add' ? (
                                                <SubmitButton
                                                    type="dashed"
                                                    loading={loadingPublish}
                                                    onClick={() => enterLoading('Publish')}
                                                    disabled={!isValid}
                                                >
                                                    {loadingDraft ? 'Saving...' : 'Save'}
                                                </SubmitButton>
                                            ) : (
                                                ''
                                            )
                                        }
                                        submitBtns={
                                            <Button.Group>
                                                <ResetButton>Reset</ResetButton>
                                                <SubmitButton
                                                    loading={loadingPublish}
                                                    disabled={!isValid}
                                                    onClick={() => enterLoading('Publish')}
                                                >
                                                    {loadingPublish ? 'Publish...' : 'Publish'}
                                                </SubmitButton>
                                            </Button.Group>
                                        }
                                    />
                                    {/* <pre style={{ flex: 1 }}>
                                        <FormikDebug />
                                    </pre> */}
                                </Form>
                            );
                        }}
                    </Formik>
                    <Divider orientation="center" style={{ color: '#333', fontWeight: 'normal' }}>
                        <Tooltip title="Go back">
                            <Button shape="circle" icon={<RollbackOutlined />} onClick={goBack} />
                        </Tooltip>
                    </Divider>
                </Col>
            </Row>
        </>
    );
};

export default WysiwygForm;

{
    /* <Button style={styles.button} type="primary" loading={loading} onClick={enterLoading}>
    {loading ? 'Saving...' : 'Save'}
</Button> */
}
{
    /* {!isReallyEmpty(values.chatUrl) && (
                                            <Iframe
                                                url={values.chatUrl}
                                                width="450px"
                                                height="450px"
                                                id="my-iframe"
                                                className="my-iframe"
                                                display="block"
                                                position="relative"
                                            />
                                        )} */
}
// const styles = {
//     button: {
//         marginTop: '10px',
//     },
// };
