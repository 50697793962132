import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { find, propEq } from 'ramda';
import BasicForm from './Forms/BasicForm';
import WysiwygForm from './Forms/WYSIWYG';

import { Content } from '../../interfaces';
import { message, Button, Row, Col, Select } from 'antd';
import { useContent } from '../../context/Content/ContentContext';
import { timestamp } from '../../firebase/firebaseHelpers';
import { isReallyNotEmpty } from './Helpers/ramdaHelpers';
const { Option } = Select;

interface Props {}

const initValues: Content = {
    id: '',
    key: '',
    title: '',
    channelId: '',
    channelKey: '',
    imgUrl: '',
    category: '',
    description: '',
    draft: false,
    public: false,
    uploadImg: false,
    createdAt: timestamp,
    editorValue: 'Write something',
    views: 1,
    currentUser: {
        id: '',
        email: '',
        name: '',
        photoURL: '',
    },
};

type ParamProps = {
    action?: string;
    id?: string;
};

const ContentForm: FC<Props> = () => {
    const { action, id } = useParams<ParamProps>();
    // console.log('action', action);
    const {
        state: { contentsOfChannel },
    } = useContent();
    const [initialContentValue, setContentValue] = useState(initValues);
    const [selectVal, setSelectVal] = useState('wysiwyg');
    // const [action, setAction] = useState('');

    useEffect(() => {
        if (isReallyNotEmpty(action) && action) {
            if (action !== 'add') {
                const currentContent = find(propEq('id', id))(contentsOfChannel) as Content;
                // console.log('currentContent', currentContent);
                setContentValue(currentContent);
                // copy or edit - grab data from store.
            }
        }
    }, [initialContentValue, action]);

    const handleSelect = (value: string) => {
        setSelectVal(value);
    };
    return (
        <>
            <h4>Content form flow</h4>
            <Select defaultValue="WYSIWYG" style={{ width: 120 }} onChange={handleSelect}>
                {/* <Option value="basic">Basic</Option> */}
                <Option value="wysiwyg">WYSIWYG</Option>
                <Option value="wysiwyg">coming soon...</Option>
            </Select>
            {/* {selectVal === 'basic' && <BasicForm />} */}
            {selectVal === 'wysiwyg' && <WysiwygForm action={action} content={initialContentValue} />}
        </>
    );
};

export default ContentForm;
