import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    databaseURL: process.env.REACT_APP_databaseURL,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebase.auth();
const firebaseStorage = firebase.storage();
const fbStorageTaskEventStateChanged = firebase.storage.TaskEvent.STATE_CHANGED;
firebase.analytics();
// firebase.analytics().logEvent('notification_received');

const incBy1 = firebase.firestore.FieldValue.increment(1);
const decBy1 = firebase.firestore.FieldValue.increment(-1);

const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebaseAuth.signInWithPopup(provider);
};

export { firebaseAuth, firebaseApp, firebaseStorage, fbStorageTaskEventStateChanged, incBy1, decBy1, signInWithGoogle };
