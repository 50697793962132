import styled from 'styled-components';

const ChannelHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 1rem;
    /* line-height: 2rem; */
    padding: 10px;
    margin-top: -105px;
    flex-wrap: wrap;
    > div {
        margin: 10px;
    }
    .userImg {
        width: auto;
        height: 70px;
        border-radius: 100%;
        transition: 500ms;
        opacity: 1;
        margin: 10px;
        background-color: gold;
        -webkit-box-shadow: 7px 7px 25px -4px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 7px 7px 25px -4px rgba(0, 0, 0, 0.3);
        box-shadow: 7px 7px 25px -4px rgba(0, 0, 0, 0.3);
        /* &:hover {
            opacity: 0.8;
            transition: 500ms;
        } */
    }
    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: row wrap;
        justify-content: space-between;

        .first-col {
            display: flex;
            flex-direction: column;
            .views {
            }
            .subscribers {
            }
            .title {
            }
            .desc {
            }
        }
        .second-col {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .social-links {
                display: flex;
                flex-direction: row;
                .link {
                    padding-right: 5px;
                    a {
                        padding: 5px;
                        line-height: 0px;
                    }
                }
            }
            .donate,
            .subscribe {
                margin-top: 5px;
                align-self: flex-end;
            }
        }
    }
`;

const ChannelContentWrapper = styled.div``;

export { ChannelHeaderWrapper, ChannelContentWrapper };
