import * as firebase from 'firebase/app';
import withFirebaseAuth from 'react-with-firebase-auth';
import 'firebase/auth';
import { firebaseApp } from './firebaseConfig';

const firebaseAppAuth = firebaseApp.auth();

/** See the signature above to find out the available providers */
const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
};

// firebaseApp.auth().onAuthStateChanged(function (user) {
//     if (user) {
//         console.log('onAuthStateChanged user', user);
//         // User is signed in.
//     }
// });

/** Create the FirebaseAuth component wrapper */
const AuthHOC = withFirebaseAuth({
    providers,
    firebaseAppAuth,
});

export default AuthHOC;
