import React, { FC, useState } from 'react';
import { isEmpty, or, prop } from 'ramda';
import { Button, InputNumber } from 'antd';
import { PaymentModal, PaymentLogo, PaymentWrapper, LogoWrapper } from './PaymentGateway.styled';
import { VenmoLogo, GoFundMeLogo, PaypalLogo, SquareCashLogo } from '../../imgs';

// TODO: https://indieweb.org/payment (ref for accepting payments through URL)
interface Props {
    donate?: {
        type?: string;
        handle?: string;
        image?: string;
        amount?: number | string; // track amount received so far
    };
}

const PaymentGatewayMain: FC<Props> = ({ donate }) => {
    const [amount, setAmount] = useState<number>(5);
    const [donateModal, setDonateModal] = useState(false);

    const onDonate = () => {
        setDonateModal(true);
    };

    const DonateComponent = () => {
        // save to db
        let openLinkURL = '';
        let handle = '';
        let logoImg = '';

        if (isEmpty(donate) || donate?.type === 'none') {
            return <p>No donation setup.</p>;
        }

        // console.log('pledge amount', amount);
        // TODO: track how many people donated and amount so far, and an input field for name

        if (donate?.type === 'venmo') {
            // open up a modal to show QR code and link to venmo account
            let customNote = `Thanks for your support from xyz insert emoji`;
            openLinkURL = `https://venmo.com/${donate.handle}?txn=pay&amount=${amount}&note=${customNote}`;
            handle = donate.handle || '';
            logoImg = VenmoLogo;
        }
        if (donate?.type === 'paypal') {
            // open up a modal to show QR code and link to venmo account
            // let customNote = `Thanks for your support from xyz insert emoji`;
            // Example: https://www.paypal.me/iamwillbk/10
            openLinkURL = `https://www.paypal.me/${donate.handle}/${amount}`;
            handle = donate.handle || '';
            logoImg = PaypalLogo;
        }
        if (donate?.type === 'squarecash') {
            // open up a modal to show QR code and link to venmo account
            // let customNote = `Thanks for your support from xyz insert emoji`;
            // https://cash.me/$username
            openLinkURL = `https://cash.me/${donate.handle}/${amount}`;
            handle = donate.handle || '';
            logoImg = SquareCashLogo;
        }
        if (donate?.type === 'gofundme') {
            openLinkURL = `https://gofundme.com/f/${donate.handle}`;
            handle = donate.handle || '';
            logoImg = GoFundMeLogo;
        }
        if (donate?.type === 'other') {
            openLinkURL = `${donate.handle}`;
            handle = donate.handle || '';
            logoImg = '';
        }

        return (
            openLinkURL && (
                <Button size="large" type="ghost" href={openLinkURL} target="_blank">
                    <LogoWrapper>
                        {!isEmpty(logoImg) && <PaymentLogo src={logoImg} alt={`${donate?.type} logo`} />}{' '}
                        <span>Donate to @{handle}</span>
                    </LogoWrapper>
                </Button>
            )
        );
    };

    const dontShowif = donate?.type === ('other' || 'gofundme');

    return (
        <div>
            <Button onClick={onDonate}>Donate</Button>

            <PaymentModal
                centered
                footer={null}
                visible={donateModal}
                title={<h3>Any pledged amount is appreciated 🙏</h3>}
                onCancel={() => setDonateModal(false)}
            >
                <PaymentWrapper>
                    {!dontShowif && (
                        <div>
                            <InputNumber
                                defaultValue={5}
                                size="large"
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value: any) => setAmount(value)}
                            />
                        </div>
                    )}
                    <div>{DonateComponent()}</div>
                </PaymentWrapper>
            </PaymentModal>
        </div>
    );
};

export default PaymentGatewayMain;
