import styled from 'styled-components';
import { Modal } from 'antd';

const PaymentModal = styled(Modal)`
    .ant-modal-content {
        padding: 15px;
    }
`;
const LogoWrapper = styled.div`
    display: inline-block;
`;
const PaymentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    div {
        margin-right: 5px;
    }
`;
const PaymentLogo = styled.img`
    width: 20px !important;
    height: auto;
`;
export { PaymentModal, LogoWrapper, PaymentWrapper, PaymentLogo };
