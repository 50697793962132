import VenmoLogo from './payment-logos/venmo-logo.png';
import GoFundMeLogo from './payment-logos/gofundme-logo.png';
import PaypalLogo from './payment-logos/paypal-logo.png';
import SquareCashLogo from './payment-logos/square-cash-logo.png';
import KofiPixelLogo from './payment-logos/kofi-pixel-logo.png';
import LoaderGif from './loader.gif';
import LoaderSvg from './loader.svg';
import RippleLoader from './ripple-1.7s-200px.gif';

export { RippleLoader, LoaderSvg, LoaderGif, VenmoLogo, GoFundMeLogo, PaypalLogo, SquareCashLogo, KofiPixelLogo };
