import React, { FC, useState, useEffect } from 'react';
import { WrappedComponentProps } from 'react-with-firebase-auth';
import AuthHOC from '../../../firebase/firebaseAuth';
import { useChannels } from '../../../context/Channel/ChannelContext';
import { isReallyNotEmpty, isPathname } from '../../../components/Channel/Helpers/ramdaHelpers';
import { Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom';

interface Props extends WrappedComponentProps {}

const WelcomeModalFirstTimer: FC<Props> = ({ user }) => {
    const history = useHistory();

    const [modalTitle, setModalTitle] = useState('Welcome to Each one teach one :)');
    const [modalText, setModalText] = useState('Welcome!');
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [thereIsACurrentUser, setThereIsACurrentUser] = useState(false);

    const {
        editChannel: updateLockName,
        state: { currentChannel, loading: loadingChannel },
    } = useChannels();

    useEffect(() => {
        if (user) return;
        const showWelcomeModal = () => {
            setModalText(
                'Enjoy and explore some content, if you want to become a creator, just sign-in with a google account.',
            );
            setVisible(true);
            setThereIsACurrentUser(false);
        };

        const timer = setTimeout(() => !user && showWelcomeModal(), 3000);
        return () => clearTimeout(timer);
    }, [user]);

    useEffect(() => {
        if (
            user &&
            isReallyNotEmpty(currentChannel?.key) &&
            isPathname('/channel', history) &&
            !currentChannel?.lockName
        ) {
            setModalTitle(`Hey ${user.displayName}, welcome aboard 👋`);
            setModalText(
                `Let's get started with updating our channel info, your channel name will be unique and used across the app. The channel name will be a pernament update this account, and if you don't choose to update, it will default to `,
            );
            setVisible(true);
            setThereIsACurrentUser(true);
        }
    }, [user, currentChannel?.key, currentChannel?.lockName]);

    // const showModal = () => {
    //     setVisible(true);
    // };

    const handleOk = () => {
        if (thereIsACurrentUser) {
            setModalText('Opening channel form...');
            setConfirmLoading(true);
            const channelFormElm = document.getElementById('channel-form');

            setTimeout(() => {
                setVisible(false);
                setConfirmLoading(false);
                channelFormElm?.click();
            }, 1000);
        } else {
            setVisible(false);
            setConfirmLoading(false);
        }
    };

    const handleCancel = async () => {
        await updateLockName({ userId: user?.uid, lockName: true }).finally(() => {
            setTimeout(() => setVisible(false), 0);
        });
    };

    const footer = thereIsACurrentUser
        ? [
              <Button key="back" onClick={handleCancel}>
                  Use default {currentChannel?.key}
              </Button>,
              <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                  Let's go Update
              </Button>,
          ]
        : [
              <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                  Cool ok
              </Button>,
          ];

    return (
        <div>
            {/* <Button type="primary" onClick={showModal}>
                Open Modal with async logic
            </Button> */}
            <Modal
                title={modalTitle}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={footer}
            >
                <p>
                    {modalText} <br />
                    {thereIsACurrentUser && <strong style={{ color: 'royalblue' }}>{currentChannel?.key}.</strong>}
                </p>
            </Modal>
        </div>
    );
};

export default AuthHOC(WelcomeModalFirstTimer);
