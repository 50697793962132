import React, { FC } from 'react';
import { Donate } from '../../interfaces';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, FormItem, Select } from 'formik-antd';
import { paymentGatewayOptions } from './Helpers/DonationOptions';
import { VenmoLogo, PaypalLogo, SquareCashLogo, GoFundMeLogo } from '../../imgs';
import { LogoWrapper, PaymentLogo } from '../Donate/PaymentGateway.styled';
import { Tooltip } from 'antd';
import isURL from 'validator/es/lib/isURL';
import { ValidationMsg } from './Forms/SocialLinkForm.styled';
const { Option } = Select;

interface Props {
    donate?: Donate | null;
    validateRequired: (value: string) => void;
}

const DonateForm: FC<Props> = ({ donate, validateRequired }) => {
    let DonateComponent;

    if (donate?.type === 'none') {
        DonateComponent = (
            <FormItem name="donate.handle" label="😢">
                <Input name="donate.handle" placeholder="no donate option" value="" disabled />
            </FormItem>
        );
    }

    if (donate?.type === 'other') {
        let Label = (
            <span>
                <LogoWrapper>
                    <span>Link</span>
                </LogoWrapper>
            </span>
        );
        DonateComponent = (
            <FormItem name="donate.handle" label={Label} required={true} validate={validateRequired}>
                <Input name="donate.handle" placeholder="URL to your donation site (i.e. https://wwww.donate.xyz...)" />

                {donate?.handle && !isURL(donate?.handle) ? (
                    <ValidationMsg>this is not a vlid URL, please update</ValidationMsg>
                ) : (
                    ''
                )}
                {donate?.handle && (
                    <>
                        <small>Preview / Test </small>
                        <small>
                            <a href={`${donate.handle}`} target="_blank">
                                {donate?.handle}
                            </a>
                        </small>
                    </>
                )}
            </FormItem>
        );
    }

    if (donate?.type === 'venmo') {
        let Label = (
            <span>
                <LogoWrapper>
                    <PaymentLogo src={VenmoLogo} alt={`${donate?.type} logo`} />
                    <span> Handle</span>
                </LogoWrapper>
            </span>
        );
        DonateComponent = (
            <FormItem name="donate.handle" label={Label} required={true} validate={validateRequired}>
                <Input name="donate.handle" placeholder="Your handle (i.e. venmo.com/your-handle)" />
                {donate?.handle && (
                    <>
                        <small>Preview: </small>
                        <small>
                            <a href={`https://venmo.com/${donate.handle}`} target="_blank">
                                @{donate?.handle}
                            </a>
                        </small>
                    </>
                )}
            </FormItem>
        );
    }

    if (donate?.type === 'paypal') {
        let Label = (
            <span>
                <LogoWrapper>
                    <PaymentLogo src={PaypalLogo} alt={`${donate?.type} logo`} />
                    <span> Handle</span>
                </LogoWrapper>
            </span>
        );
        DonateComponent = (
            <FormItem name="donate.handle" label={Label} required={true} validate={validateRequired}>
                <Input name="donate.handle" placeholder="Your handle (i.e. paypal.me/your-handle)" />
                {donate?.handle && (
                    <>
                        <small>Preview: </small>
                        <small>
                            <a href={`https://www.paypal.me/${donate.handle}`} target="_blank">
                                @{donate?.handle}
                            </a>
                        </small>
                    </>
                )}
            </FormItem>
        );
    }

    if (donate?.type === 'squarecash') {
        let Label = (
            <span>
                <LogoWrapper>
                    <PaymentLogo src={SquareCashLogo} alt={`${donate?.type} logo`} />
                    <span>$Cashtag</span>
                </LogoWrapper>
            </span>
        );
        DonateComponent = (
            <FormItem name="donate.handle" label={Label} required={true} validate={validateRequired}>
                <Input name="donate.handle" placeholder="Your $cashtag (i.e. cash.me/$yourcashtag)" />
                {donate?.handle && (
                    <>
                        <small>Preview: </small>
                        <small>
                            <a href={`https://cash.me/${donate.handle}`} target="_blank">
                                @{donate?.handle}
                            </a>
                        </small>
                    </>
                )}
            </FormItem>
        );
    }

    if (donate?.type === 'gofundme') {
        let Label = (
            <span>
                <LogoWrapper>
                    <PaymentLogo style={{ width: '32px' }} src={GoFundMeLogo} alt={`${donate?.type} logo`} />
                </LogoWrapper>
            </span>
        );
        DonateComponent = (
            <FormItem name="donate.handle" label={Label} required={true} validate={validateRequired}>
                <Input name="donate.handle" placeholder="Your link (i.e. gofundme.com/f/your-link)" />
                {donate?.handle && (
                    <>
                        <small>Preview: </small>
                        <small>
                            <a href={`https://gofundme.com/f/${donate?.handle}`} target="_blank">
                                gofundme.com/f/{donate?.handle}
                            </a>
                        </small>
                    </>
                )}
            </FormItem>
        );
    }

    type HelpLinksType = {
        venmo: string;
        paypal: string;
        squarecash: string;
        gofundme: string;
        other: string;
        none: string;
    };

    const HelpLinks: HelpLinksType = {
        venmo: 'https://help.venmo.com/hc/en-us/articles/235432448-Check-or-Edit-Your-Username',
        paypal: 'https://www.paypal.com/us/smarthelp/article/how-do-i-manage-my-paypal.me-link-faq3921',
        squarecash: 'https://cash.app/help/us/en-us/3123-cashtags',
        gofundme: 'https://support.gofundme.com/hc/en-us/articles/115011740788-How-to-Use-Your-GoFundMe-Link',
        other: '',
        none: '',
    };

    type PaymentType = keyof HelpLinksType;

    const helpInfo = (paymentType: PaymentType) => {
        if (paymentType === 'none' || paymentType === 'other') return;
        const title = (
            <span>
                Get help with{' '}
                <a href={HelpLinks[paymentType]} target="_blank" rel="noopener noreferrer">
                    {paymentType}
                </a>
            </span>
        );

        return (
            <span style={{ marginLeft: '5px' }}>
                <Tooltip placement="top" title={title}>
                    <InfoCircleOutlined />
                </Tooltip>
            </span>
        );
    };

    return (
        <>
            <div className="ant-row ant-form-item">
                <div className="ant-col ant-form-item-label ant-col-xs-25 ant-col-md-7">
                    <label className="ant-form-item-required" title="Payment type">
                        Payment type {donate?.type === ('none' || 'other') ? '' : helpInfo(donate?.type as PaymentType)}
                    </label>
                </div>
                <Select
                    name="donate.type"
                    defaultValue="donate.type"
                    className="ant-col ant-form-item-control ant-col-xs-25 ant-col-md-18"
                >
                    {paymentGatewayOptions.map((pay, i) => {
                        return (
                            <Option key={i} value={pay}>
                                {pay}
                            </Option>
                        );
                    })}
                </Select>
            </div>
            {DonateComponent}
        </>
    );
};

export default DonateForm;
