import React, { useCallback, useEffect, useState } from 'react';
import { db, timestamp } from '../../firebase/firebaseHelpers';
import { getNotificationData } from './utility';

type SnapshotDocProps = {
    metadata: { hasPendingWrites: any };
    data: () => any;
    exists: any;
};

const channelsRef = db.collection('channels');
const notificationsRef = db.collection('notifications');

const useNotifications = () => {
    const [notifications, setNotifications] = useState({});

    // let unsub: { (): void | undefined; (): void };
    const getNotifications = useCallback(
        (chanId: string | undefined) => {
            // todo: unsub here figure out
            const unsub = notificationsRef.doc(chanId).onSnapshot(
                (doc: SnapshotDocProps) => {
                    var source = doc.metadata.hasPendingWrites ? 'Local' : 'Server';
                    if (doc.exists) {
                        const data = doc.data();
                        return setNotifications(data);
                    } else {
                        return {};
                    }
                },
                (error) => {
                    console.log('useNotifications -> error', error);
                },
            );
        },
        [notificationsRef],
    );

    const getChannelById = useCallback(
        async (id) => {
            try {
                let res = await channelsRef
                    .doc(id)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const channelData = doc.data();
                            const notifData = getNotificationData(channelData); // curried func
                            return notifData;
                        }
                    })
                    .catch((error) => {
                        console.log('Error getting getChannelById documents: ', error);
                    });
                return res;
            } catch (error) {
                console.log('getChannelById error', error);
            }
        },
        [channelsRef],
    );

    const notifySuber = useCallback(
        async (currentChannel: any, currentAuthUser: any) => {
            try {
                let userChannelData = await getChannelById(currentAuthUser.uid);
                let res = await notificationsRef.doc(currentChannel.userId).set(
                    {
                        [currentAuthUser.uid]: {
                            name: currentAuthUser.displayName,
                            channelData: userChannelData,
                            notifiedAt: timestamp,
                            notificationCheck: false,
                        },
                    },
                    { merge: true },
                );
                return res;
            } catch (error) {
                console.log('notifySub -> saving error', error);
            }
        },
        [notificationsRef, getChannelById],
    );

    const notifySuberChecked = useCallback(
        async (chanId: string, suberId: any) => {
            try {
                let res = await notificationsRef.doc(chanId).set(
                    {
                        [suberId]: {
                            notificationCheck: true,
                        },
                    },
                    { merge: true },
                );

                return res;
            } catch (error) {
                console.log('notifySub -> saving error', error);
            }
        },
        [notificationsRef],
    );

    // useEffect(() => {
    //     // return () => unsub();
    // }, []);

    return {
        notifications,
        getNotifications,
        notifySuber,
        notifySuberChecked,
    };
};

export default useNotifications;
